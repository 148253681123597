import { HasOwnProperty } from "../GlobalFunctions";

$(() => {
    $(".table-listing label.toggleSwitch").not(".noPostBack").off("click").on("click", function (event) {
        event.stopPropagation();
        event.preventDefault();
        var self = $(this);
        var url = $(this).data("updateurl");
        var checkbox = $(this).find("input:checkbox");

        if (url && checkbox.length) {
            var checkValue = !$(checkbox[0]).is(":checked");

            $.post(url, { published: checkValue }, (response) => {
                if (response.Succeeded === true) {
                    checkbox.prop("checked", checkValue);

                    if (response.Result) {
                        var tr = self.closest("tr");

                        for (const property in response.Result) {
                            if (HasOwnProperty(response.Result, property)) {
                                tr.find("." + property).text(response.Result[property]);
                            }
                        }
                    }
                } else {
                    return false;
                }
            });
        }
    });
});