////const localStorageAvailable = (): boolean => {
////    const test = 'test_' + (new Date()).getTime();
////    try {
////        localStorage.setItem(test, test);
////        localStorage.removeItem(test);
////        return true;
////    } catch (e) {
////        return false;
////    }
////}

const supportsLocalStorage = typeof (window.Storage) !== "undefined" ? true : false;
const storageKey = "EasylonStorage";
let data = {};

const Load = (): void => {
    if (supportsLocalStorage) {
        const json = localStorage.getItem(storageKey);

        if (json) {
            data = JSON.parse(json);
        } else {
            data = {};
        }
    } else {
        data = {};
    }
};

const Save = (): void => {
    localStorage.setItem(storageKey, JSON.stringify(data));
};

const SetItem = (key: string, value: any): void => {
    data[key] = value;
    Save();
};

const GetItem = (key: string): any => {
    Load();

    if (data[key]) {
        return data[key];
    }

    return null;
};

const GetAllItems = (): any => {
    return data;
};

$(() => {
    Load();
});

export const EasylonStorageManager = {
    Load,
    Save,
    SetItem,
    GetItem,
    GetAllItems
};