import { serlizeForm2Object } from "./FormFunctions";
import { hideFloatingElements, InitializeApp, loadRss, ShowErrors, UpdateScrollBars } from "./GlobalFunctions";
import { GlobalSettings } from "./GlobalSettings";
import { xhrManager } from "./xhrManager";
import datepickerFactory from "jquery-datepicker";
import { ImageUploadFunctions } from "./ImageUploadFunctions";
import { CustomSerializedForm } from "./interfaces";
import { EasylonStorageManager } from "./EasylonStorageManager";

import "./onloadBingings";

datepickerFactory($);
/*const searchInput = $("#q");*/
let initialSerializedForm: CustomSerializedForm;

$(function () {
    const dataRowIdentifier = "update-row";

    $(`[data-${dataRowIdentifier}]`).each(function () {
        const self = $(this);

        self.on("click", (event: Event) => {
            event.preventDefault();
            event.stopPropagation();

            const updateContainer = self.hasClass("update-root") ? self : self.closest("update-root");

            const url = self.data("update-row");

            if (url) {
                $.post(url, (response) => {
                    if (response.Succeeded) {
                        updateContainer.replaceWith($(response.View || ""));
                    } else {
                        if (response.Errors) {
                            ShowErrors(response.Errors);
                        }
                    }
                });
            }
        });
    });

    $("form").submit(() => {
        const form = $(this);

        form.find(".toggleSwitch").each((index: number, elem: HTMLElement) => {
            const toggle = $(elem);
            //debugger
            const checkbox = toggle.find("input[type=checkbox]");
            const checked = checkbox.is(":checked");
            let hiddenInput = toggle.find("input[type=text]");

            if (!hiddenInput.length) {
                hiddenInput = $("<input type='text' />");
                hiddenInput.attr("name", checkbox.attr("name") as string);
                toggle.append(hiddenInput);
            }

            hiddenInput.attr("value", checked ? "True" : "False");
        });
    });

    //Todo: make all alert functions into one function
    $(".headerItem .close").on("click", function () {
        if ($(this).parent() && $(this).parent().hasClass("alert-info")) {
            return;
        }

        $(this).closest("tr").hide(0, () => {
            UpdateScrollBars();
        });

        //$(this).closest(".headerItem").hide(0, function() {
        //    easylon.UpdateScrollBars();
        //});

        $(this).closest(".headerItem").removeClass("headerItem-Visible");
        UpdateScrollBars();
    });

    $(".toggleSiteMapItem").click("click", function () {
        const btn = $(this);
        const node = btn.closest(".siteMapNode").find(".siteMap").first();
        const icon = btn.find(".fa");

        if (node.length) {
            node.slideToggle(0, () => {
                if (node.is(":visible")) {
                    icon.attr("class", "fa fa-chevron-up");
                } else {
                    icon.attr("class", "fa fa-chevron-down");
                }

                $("#ContentPanel").find(".nano").nanoScroller({ alwaysVisible: false });
            });
        }
    });

    $(".infoPanelText").on("click", function () {
        $(this).closest(".alert").hide();
    });

    $(".toggleSiteMapItem").click("click", function () {
        $(this).find(".siteMapItem").first().toggle();
    });

    $("#SideMenuOverlay").on("click", function () {
        const leftMenu = $(".leftMenu");
        leftMenu.removeClass("leftMenu-maximized");
        leftMenu.addClass("leftMenu-minimized");

        EasylonStorageManager.SetItem("LeftMenuState", "leftMenu-minimized");
        $("#SubHeader").removeClass("leftMenu-expanded");

        $(this).hide(0);
    });

    $(".menuToggleBtn").on("click", function () {
        //var btn = $(this);
        //var title = btn.find('.title');

        const leftMenu = $(".leftMenu");

        //var hasClass = leftMenu.hasClass("leftMenu-maximized");

        if (leftMenu.hasClass("leftMenu-maximized")) {
            leftMenu.removeClass("leftMenu-maximized");
            leftMenu.addClass("leftMenu-minimized");

            EasylonStorageManager.SetItem("LeftMenuState", "leftMenu-minimized");
            $("#SubHeader").removeClass("leftMenu-expanded");
            $("#SideMenuOverlay").hide(0);
        }
        else if (leftMenu.hasClass("leftMenu-minimized")) {
            leftMenu.removeClass("leftMenu-minimized");
            leftMenu.addClass("leftMenu-maximized");

            EasylonStorageManager.SetItem("LeftMenuState", "leftMenu-maximized");
            $("#SubHeader").addClass("leftMenu-expanded");

            if ((window.screen.width <= 768)) {
                $("#SideMenuOverlay").show(0);
            }
            else {
                $("#SideMenuOverlay").hide(0);
            }
        }
        else {
        }
    });

    bindPanels();

    $(document).click(() => {
        hideFloatingElements();

        $(".btn-delete").removeClass("btn-deleteConfirm").removeClass("btn-deleteConfirmStatic");
    });

    $(".sideBarSearchInput").on("input", function (e: JQueryEventObject) {
        const arr = $(this).closest(".tableRow").find("li");

        const value = $(e.target).val() as string;
        if (value.trim()) {
            const searchString = value.toLowerCase();

            arr.each(function () {
                const rowText = $(this).text().trim().toLowerCase();

                if (rowText.indexOf(searchString) !== -1) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        } else {
            arr.show();
        }
    });

    loadRss();
    InitializeApp();
});

$("body").on("click", () => {
    $(".dropdown-menu").hide();
});

window.onbeforeunload = function () {
    xhrManager.abortAll();
    window.stop();

    //Todo: add remove on load
    if (window["loadingImages"]) {
        for (let i = 0; i < window["loadingImages"]; i++) {
            const image = window["loadingImages"][i];

            if (!image.complete) {
                image.attr("src", "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEAAAAALAAAAAABAAEAAAI=;");
            }
        }
    }
};

function bindPanels() {
    ImageUploadFunctions.initialize();

    $(".toggleShowPanelBody").on("click", function () {
        const panel = $(this).closest(".panel-main");

        if (panel.length) {
            const panelBody = panel.find(".panel-body");
            const icon = $(this).find(".fa");
            panelBody.stop();

            panelBody.slideToggle({
                duration: 200,
                complete: function () {
                    $(".panelContentContainer .nano").nanoScroller({ alwaysVisible: false });

                    if ($(this).is(":visible")) {
                        panel.find(".bodyCollapsed").hide();
                        panel.find(".bodyExpanded").show();
                    } else {
                        panel.find(".bodyExpanded").hide();
                        panel.find(".bodyCollapsed").show();
                    }
                }
            });
        }
    });
}

function postRefreshForm(form, xhr, additional?: any) {
    const formData = additional || {};

    const validator = $("form").validate();
    validator["checkForm"]();

    if (!validator.valid()) {
        validator["showErrors"]({});
        return;
    }

    $("input, select, textarea", form).each(function () {
        const field = $(this);
        const fieldName = (this as HTMLInputElement).name;

        if (field.is(":checkbox")) {
            formData[fieldName] = field.is(":checked");
        } else {
            formData[fieldName] = field.val();
        }
    });

    if (xhr) {
        xhr.abort();
    }

    return $.post(form.attr("form-refresh-url"), formData, (response) => {
        form.find(".formRefreshUpdatePanel").replaceWith($(response));
        bindPanels();
        bindRefresh();
    });
}

function bindRefresh() {
    //console.log("bindRefresh();");

    $("[form-refresh-url]").each(function () {
        let xhr: JQuery.jqXHR | undefined;
        const form = $(this);

        $("input[type=date], input[type=datetime], .datepicker").datepicker({
            dateFormat: GlobalSettings.DatePickerFormat,
            onSelect: function (dateText, inst) {
                $(inst.input).trigger("change");
            }
        });

        $("form.mainForm").valid();

        form.keydown((event) => {
            if (event.keyCode == 13) {
                event.preventDefault();
                return false;
            }
        });

        form.find("select.form-control.refresh").change(function () {
            const field = $(this);
            const name = field.attr("name");
            xhr = postRefreshForm(form, xhr, { "RefreshProperty": name });
        });

        form.find(".form-control.refresh").not("select").change(function () {
            const field = $(this);
            const name = field.attr("name");
            xhr = postRefreshForm(form, xhr, { "RefreshProperty": name });
        })
            .keydown(function (event) {
                if (event.keyCode == 13) {
                    const control = $(this);
                    if (this.tagName === "INPUT" && (!control.is(":checkbox") && !control.is(":radio"))) {
                        if (control.attr("oldValue") !== control.val()) {
                            //console.log("changed ya'al");
                            const name = control.attr("name");
                            xhr = postRefreshForm(form, xhr, { "RefreshProperty": name });
                        }
                        control.removeAttr("oldValue");
                    }
                }
            })
            .focus(function () {
                const control = $(this);
                control.attr("oldValue", control?.val() as string);
            }).blur(function () {
                const control = $(this);
                if (control.attr("oldValue") !== control.val()) {
                    //console.log("changed ya'al");

                    const name = control.attr("name");
                    xhr = postRefreshForm(form, xhr, { "RefreshProperty": name });
                }
                control.removeAttr("oldValue");
            });
    });
}

$(() => {
    xhrManager.Initialize();
    bindRefresh();

    initialSerializedForm = serlizeForm2Object("form.mainForm");
    delete initialSerializedForm["g-recaptcha-response"];
});