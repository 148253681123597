const Update = (next: { (): void } | undefined): void => {
    $(".breadcrumbDropdown").hide(0);
    $(".breadcrumbMainNode").removeClass("breadcrumbMainNode-wrap");

    const breadCrumbContainer = $(".breadCrumbContainer");
    const breadCrumbContainerInner = breadCrumbContainer.find(".breadCrumbContainerInner");
    const breadcrumb = breadCrumbContainer.find(".breadcrumb");

    const className = "visible-items";

    const items = breadcrumb.find("> li");
    items.hide();

    $(items[0]).addClass(className).show();
    $(items[items.length - 1]).addClass(className).show();

    $(items.not(className).get().reverse()).each(function () {
        const li = $(this);
        li.show();
        const w1 = breadCrumbContainerInner?.width();
        const w2 = breadCrumbContainer?.width();

        if (w1 && w2 && w1 > w2) {
            li.hide();
            return;
        }
    });

    if (breadCrumbContainerInner != undefined) {
        const w1 = breadCrumbContainerInner?.width();
        const w2 = breadCrumbContainer?.width();

        if (w1 && w2 && w1 > w2) {
            $(".breadcrumbMainNode").addClass("breadcrumbMainNode-wrap");
        }
    }


    if (next) {
        next();
    }
};

export const Breadcrumb = {
    Update
};