import { getUrlVars, getUrlVarsFromUrl } from "../GlobalFunctions";

$(() => {
    $(".sortableColumn").on("click", function () {
        const urlVars = getUrlVars();
        //const keys = Object.keys(urlVars);
        const hyperlink = $(this).find("a")[0];
        const hyperLinkVars = getUrlVarsFromUrl(hyperlink["href"]);

        const newUrlVars = { ...urlVars, ...hyperLinkVars };
        const urlKvp: Array<string> = [];

        for (const [key, value] of Object.entries(newUrlVars)) {
            urlKvp.push(`${encodeURIComponent(key)}=${encodeURIComponent(value || "")}`);
        }

        if (urlKvp.length) {
            hyperlink["href"] = "?" + urlKvp.join("&");
        }
    });
});

