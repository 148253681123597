import * as ko from "knockout";
import { getInputValue, HasOwnProperty, showModal, UpdateScrollBars } from "./GlobalFunctions";
import jqueryValidation from "jquery-validation";
import "jquery-validation-unobtrusive";
import { CustomSerializedForm, PopupModal, PopupModalType } from "./interfaces";

jqueryValidation($);

let audits: ko.ObservableArray<any>;
let headers: ko.ObservableArray<any>;
let initialSerializedForm: CustomSerializedForm;

export const serlizeForm2Object = (form: string): CustomSerializedForm => { //Todo: Fix typo
    const data = {};
    let count = 0;
    $(form).find(":input").not("button, submit").each(function () {
        const input = $(this);
        const name = input.attr("name");
        if (input.is("[type=checkbox]")) {
            if (name) {
                data[name] = input.prop("checked");
            }

            count++;
        } else {
            if (name && !data[name]) {
                data[name] = input.val();
                count++;
            }
        }
    });

    return {
        Fields: data,
        Count: function () {
            return count;
        }
    };
};

export const hasFormChanged = (): boolean => {
    if (initialSerializedForm) {
        const currentSerializedForm: CustomSerializedForm = serlizeForm2Object("form.mainForm");

        if (!currentSerializedForm) {
            return false;
        }

        delete currentSerializedForm.Fields["g-recaptcha-response"];
        delete initialSerializedForm.Fields["g-recaptcha-response"];

        if (initialSerializedForm.Count() !== currentSerializedForm.Count()) {
            return true;
        }

        for (const prop in initialSerializedForm.Fields) {
            if (HasOwnProperty(initialSerializedForm.Fields, prop)) {

                if (!HasOwnProperty(currentSerializedForm.Fields, prop)) {
                    return true;
                }

                if (initialSerializedForm.Fields[prop] !== currentSerializedForm.Fields[prop]) {
                    return true;
                }
            }
        }
    }

    return false;
};


const getAntiForgeryToken = (): string => {
    return getInputValue(getAntiForgeryInput());
};

const getAntiForgeryInput = () => {
    return $("input[name=\"__RequestVerificationToken\"]");
};

const jsonDelete = (url: string, success: (d: unknown) => void, params = {}): void => {
    const antiforgeryInput = getAntiForgeryInput();

    params["__RequestVerificationToken"] = getAntiForgeryToken();

    const ajaxObj = {
        url: url,
        type: "post",
        data: params,
        dataType: "json",
        success: function (data) {
            if (data.Succeeded) {
                antiforgeryInput.val(data.AntiforgeryToken);

                if (typeof (success) === "function") {
                    success(data);
                }
            }
        }
    };

    $.ajax(ajaxObj);
};


const jsonPost = (url: string, callback: (d: unknown) => void, data = {}): void => {
    const antiforgeryInput = getAntiForgeryInput();

    data["timezoneoffset"] = new Date().getTimezoneOffset();
    data["__RequestVerificationToken"] = antiforgeryInput;

    const ajaxObj = {
        url: url,
        type: "post",
        data: data,
        dataType: "json",
        success: function (data) {
            if (typeof (callback) === "function") {
                callback(data);
            }
        }
    };

    $.ajax(ajaxObj);
};

export const toDateFromJson = (src: string): Date => {
    return new Date(parseInt(src.substr(6)));
};

export const ConcatenateFieldValue = (dropdown: JQuery, valueFields: Array<any>): void => {
    function toggleDisplay(valid) {

        if (valid == valueFields.length) {
            dropdown.show();
        } else {
            dropdown.hide();
        }
    }

    function UpdateValues() {

        dropdown.find("option:visible").first().attr("selected", "true");

        const listItem = dropdown.find("option:selected");

        if (!listItem) {
            return;
        }

        const format = listItem.data("format");

        if (!format) {
            return;
        }

        let value = format;
        let valid = 0;

        for (let x = 0; x < valueFields.length; x++) {
            const entry = valueFields[x];

            if (entry.required) {
                if (entry.field.val().replace(/\s/g, "").length) {
                    valid += 1;
                }
            } else {
                valid += 1;
            }

            value = value.split("{" + x + "}").join(entry.field.val());
        }

        dropdown.find("option").each(function () {
            var listItem = $(this);

            var fmt = listItem.data("format");

            if (fmt) {
                var value = fmt;

                for (var x in valueFields) {
                    var entry2 = valueFields[x];
                    value = value.split("{" + x + "}").join(entry2.field.val());
                }

                listItem.text(value);
            }
        });

        toggleDisplay(valid);
    }

    for (var i in valueFields) {
        var entry = valueFields[i];
        var vf = entry.field;

        vf.bind("keyup input", () => {
            UpdateValues();
        });
    }

    dropdown.change(() => {
        UpdateValues();
    });

    UpdateValues();
};

$(() => {
    $(".navigationButton").on("click", (e) => {
        if (hasFormChanged()) {
            if (!confirm("wil je weg ya'al?")) {
                e.preventDefault();
                return;
            }
        }
    });

    $(".submitFormButton").on("click", function () {
        if ($(".mainForm").attr("submitting") === "true") {
            return;
        }

        const button = $(this);
        if (button.data("action")) {
            $(".mainForm").attr("action", button.data("action"));
        }

        if ($(".mainForm").valid()) {
            $(".mainForm").attr("submitting", "true");
            $(".alert-info").hide();
            $(".mainForm").submit();
            $(this).attr("disabled", "disabled");
        }
    });

    $(".btn-delete").on("click", function (event) {
        event.preventDefault();

        const btn = $(this);

        $(".btn-delete").not(btn).removeClass("btn-deleteConfirm");

        if (btn.hasClass("btn-deleteConfirm")) {
            jsonDelete(btn.data("url"), (response: any) => {
                if (response.Success || response.Succeeded) {
                    btn.closest("tr").remove();
                }
            });
        } else {
            btn.addClass("btn-deleteConfirm");
        }

        return false;
    });

    $(".btn-json").on("click", function (event) {
        event.preventDefault();

        const btn = $(this);
        //var params = $(this).data();

        jsonPost(btn.data("url"), (response: any) => {

            if (response.Succeeded) {
                location.reload();
            } else {
                alert("Er is een fout opgetreden");
            }
        });

        return false;
    });

    audits = ko.observableArray();
    headers = ko.observableArray();

    const historyPanel = $("#HistoryPanel");

    if (historyPanel.length) {
        ko.applyBindings({
            "Audits": audits,
            "Headers": headers,
            toDateFromJson: toDateFromJson
        }, historyPanel[0]);
    }


    $(".menuBtn-history").on("click", function (event) {

        event.preventDefault();

        const historyPanel = $("#HistoryPanel");
        const btn = $(this);

        if (historyPanel.is(":visible")) {
            historyPanel.hide(0, () => {
                const historyPanel = $("#HistoryPanel");
                const btnMore = historyPanel.find(".btn-more");
                btnMore.data("expanded", "false");
                btnMore.text("Toon meer");

                //const inner = $('#HistoryPanel').find("table");
                $("#HistoryPanel").find("> td").height(btnMore.data("defaultHeight"));

                $("#ContentPanel").show(0);
            });

            UpdateScrollBars();
            return;
        }

        //const params = $(this).data();

        jsonPost(btn.data("url"), (response: any) => {

            if (response.Succeeded) {
                const h = $("#HistoryPanel").data("headers").split(",");

                audits(response.Audits);
                headers(h);

                historyPanel.show(0, () => {
                    UpdateScrollBars();
                });
            } else {
                alert("Er is een fout opgetreden");
            }
        });

        return false;
    });


});

const onFormSubmit = (e): void => {
    if (hasFormChanged()) {
        const modalData = {
            Message: window["comfirmPageLeaveMessage"],
            Type: PopupModalType.OkCancel
        } as PopupModal;

        if ($(e.target).is("a, a *")) {
            //modalData.Url = $(this).attr("href");
            modalData.Url = $(e.target).attr("href"); //Todo: check this
        }

        if (showModal(modalData)) {
            e.preventDefault();
        }
    }
};

export const form = {
    serlizeForm2Object,
    hasFormChanged,
    toDateFromJson,
    jsonDelete,
    jsonPost,
    ConcatenateFieldValue,
    onFormSubmit
};