$(() => {
    $(".invoerStaticPanel .closeRowBtn").on("click", function () {
        const row1 = $(this).closest(".itemsRow");
        const index = $(".invoerStaticPanel .itemsRow").index(row1);
        const row2 = $(".invoerHorSlidePanel .itemsRow:eq( " + index + " )");

        row1.removeClass("itemsRow-Expanded");
        row2.removeClass("itemsRow-Expanded");
        row1.data("selector", "");
    });

    $(".invoerStaticPanel .sBtn").on("click", function () {
        const optionId = $(this).data("selector");

        if (!optionId) {
            $(".itemsRow-Expanded").removeClass("itemsRow-Expanded");
            return;
        }

        const row1 = $(this).closest(".itemsRow");
        const index = $(".invoerStaticPanel .itemsRow").index(row1);

        const row2 = $(".invoerHorSlidePanel .itemsRow:eq( " + index + " )");

        const isExpanded = row1.hasClass("itemsRow-Expanded");

        row2.parent().find(".subPnlItem").hide();

        if (!isExpanded) {
            $(".itemsRow-Expanded").removeClass("itemsRow-Expanded");
            row1.addClass("itemsRow-Expanded");
            row2.addClass("itemsRow-Expanded");

            row1.data("selector", optionId);
            const panel = row2.parent().find("." + optionId);
            panel.show();
            row1.find(".subPanelTitle").text(panel.data("title"));
        } else {
            const selected = row1.data("selector");

            if (selected == optionId) {
                row1.removeClass("itemsRow-Expanded");
                row2.removeClass("itemsRow-Expanded");
                row1.data("selector", "");
            } else {
                row1.data("selector", optionId);
                const panel = row2.parent().find("." + optionId);
                panel.show();
                row1.find(".subPanelTitle").text(panel.data("title"));
                //Todo: show window
            }
        }
    });
});

