//interface fileResoure {
//    type: string,
//    name: string;
//}

interface savedFile {
    ts: unknown,
    fs: File;
}

function initialize(element: HTMLElement, rowTemplate: string, mimeTypes: string[]): void {
    const container = $("#UploadResultList");
    let rowId = 0;

    const isValidMime = function (mime: string) {
        if (!mimeTypes) {
            return true;
        }

        let isvalid = false;

        Object.keys(mimeTypes).forEach((key) => {
            const item = mimeTypes[key];

            if (item.MimeType == mime) {
                isvalid = true;
                return;
            }
        });

        return isvalid;
    };

    const getIcon = function (mime: string): string {
        let icon = "fa fa-file";
        Object.keys(mimeTypes).forEach((key) => {
            const item = mimeTypes[key];

            if (item.MimeType == mime) {
                icon = item.Icon;
                return;
            }
        });

        return icon;
    };

    //const FileListItems = (files) => {
    //    var b = new ClipboardEvent("").clipboardData || new DataTransfer()
    //    for (var i = 0, len = files.length; i < len; i++) {
    //        var file = files[i];
    //        file.ts = (new Date()).getTime();
    //        b.items.add(files[i])
    //    }
    //    return b.files
    //}

    let savedFiles: savedFile[] = [];

    function removeFile(ts: unknown) {

        savedFiles = savedFiles.filter(i => i.ts != ts);
        createFileList();
    }

    function addToList(files: FileList) {
        //const uploadFiles = $("#uploadFiles")[0] as HTMLInputElement;

        const errorsPanel = $("#uploadPanelListErrors");
        errorsPanel.hide(0);
        const errors = errorsPanel.find("ul");
        errors.empty();
        let hasErrors = false;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const id = rowId;
            rowId++;

            if (isValidMime(file.type)) {
                savedFiles.push({
                    ts: (new Date()).getTime() + "_" + id,
                    fs: file
                });
            } else {
                hasErrors = true;
                errors.append($(`<li>${file.name}</li>`));
            }
        }

        if (hasErrors) {
            errorsPanel.show(0);
        }

        //uploadFiles.files = FileListItems(savedFiles);
        createFileList();
    }

    function createFileList() {
        const uploadList = container.closest(".uploadPanelList");
        container.empty();
        uploadList.hide(0);

        const deleteFunction = function (id: unknown) {
            return function (e: MouseEvent) {
                e.preventDefault();
                e.stopPropagation();

                if (e.target) {
                    const element = $(e.target);

                    if (element.hasClass("btn-deleteConfirmStatic")) {
                        removeFile(id);
                    } else {
                        element.addClass("btn-deleteConfirmStatic");
                    }
                }
            };
        };

        const b = new ClipboardEvent("").clipboardData || new DataTransfer();
        for (let i = 0, len = savedFiles.length; i < len; i++) {
            const item = savedFiles[i];
            const file = item.fs;
            b.items.add(file);

            const div = $(rowTemplate);
            div.find(".filename").text(file.name);

            const fileClass = "fa-file";
            div.find(".fa-file").attr("class", getIcon(file.type));

            div.find(".btn-delete").on("click", () => {
                deleteFunction(item.ts);
            });

            container.append(div);
            uploadList.show(0);
        }
        return b.files;
    }

    //function renderList() {
    //    var div = $(rowTemplate);

    //    var d = div.find('.deleteUploads');
    //    d.on('click', function() {
    //        console.log('....');
    //        div.remove();
    //        //current.remove();

    //        if (!container.find('td').length) {
    //            container.closest('.uploadPanelList').hide(0);
    //        }
    //    });

    //    div.find('.filename').text(ff.name);

    //    var fileClass = "fa-file";
    //    div.find('.fa-file').attr("class", getIcon(ff.type))
    //    container.append(div);

    //    container.closest('.uploadPanelList').show(0);
    //}

    const addFiles = function (files: FileList) {
        addToList(files);
    };

    //var addFiles = function(fileList) {
    //    var uploadFiles = $("#uploadFiles")[0] as HTMLInputElement;

    //    var fileBuffer = [];
    //    Array.prototype.push.apply(fileBuffer, uploadFiles.files);
    //    Array.prototype.push.apply(fileBuffer, fileList);
    //    uploadFiles.files = FileListItems(fileBuffer);
    //    debugger
    //    var oldInput = $("#fileupload");
    //    var newInput = $(oldInput[0].outerHTML);
    //    oldInput.replaceWith(newInput);
    //    bindUploadControl(newInput);
    //}

    const onInputChange = function (e: JQueryEventObject) {
        if (!e.target) {
            return;
        }

        const current = $(e.target);
        const parent = current.parent();

        const fileList: FileList | null = (current[0] as HTMLInputElement).files;

        if (fileList) {
            addFiles(fileList);
        }
    };

    function uploadControlDragEvent(e) {
        e.preventDefault();
        e.stopPropagation();
        const dt = e.originalEvent.dataTransfer;
        addFiles(dt.files);
    }

    function bindUploadControl(control: HTMLElement) {
        $(control).on("drop", uploadControlDragEvent);
        $(control).on("change", onInputChange);

        const errorsPanel = $("#uploadPanelListErrors");

        errorsPanel.find(".close").on("click", () => {
            errorsPanel.hide(0);
        });
    }

    bindUploadControl(element);
}

export const fileuploadControl = {
    initialize
};