import { UpdateScrollBars } from "../GlobalFunctions";

$(() => {
    var historyPanel = $("#HistoryPanel");


    $("#HistoryPanel .btn-more").on("click", function () {
        const btn = $(this);
        const historyPanel = $("#HistoryPanel");

        if (btn && historyPanel) {
            if (btn.data("expanded") && btn.data("expanded") == "true") {
                $("#ContentPanel").show(0, () => {
                    btn.data("expanded", "false");
                    btn.text("Toon meer");

                    const inner = $("#HistoryPanel").find("table");
                    inner.find("tbody").height(btn.data("defaultHeight"));
                    $("#HistoryPanel").height("224px");
                });
            } else {
                $("#MainPanelSearch, #MainInfoContainer, #ContentPanel").not(historyPanel).hide(0, () => {
                    btn.data("expanded", "true");

                    const inner = $("#HistoryPanel").find("table");

                    if (inner) {
                        const tbody = inner.find("tbody") as JQuery;
                        const thead = inner.find("thead");

                        if (thead && tbody) {
                            btn.data("defaultHeight", tbody.height() || 0);
                            inner.find("tbody").height((historyPanel.height() || 0) - ((thead.height() || 0) + (btn.height() || 0)));
                        }

                        $("#HistoryPanel").height("100%");
                        btn.text("Toon minder");
                    }

                });
            }

            setTimeout(() => {
                UpdateScrollBars();
            }, 100);
        }
    });
});