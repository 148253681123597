var setupValidationMethods = function () {
    if ($.validator && $.validator.methods) {

        $.validator.methods["range"] = function (value, element, param) {
            var globalizedValue = value.replace(",", ".");
            return this.optional(element) || (globalizedValue >= param[0] && globalizedValue <= param[1]);
        };

        $.extend($.validator.methods, {
            number: function (value, element) {
                var result = this["optional"](element) || /^-?(?:\d+|\d{1,3}(?:[\s.,]\d{3})+)(?:[.,]\d+)?$/.test(value);
                return result;
            }
        });
    }
};

//function onError(error, inputElement) {
//    console.log("error");
//    //if (!error) {
//    //    return;
//    //}
//}

$(() => {
    setupValidationMethods();
    //const $form = $("form");
    //if ($form.length) {
    //    const validator = $form.data("validator");

    //    if (validator) {
    //        validator.settings.errorPlacement = $.proxy(onError, $form);
    //    }
    //}

    let decimalSepparator = "";
    let decimalSepparator2Replace = "";

    if ((1 / 4).toString().indexOf(".") === -1) {
        decimalSepparator = ",";
        decimalSepparator2Replace = ".";
    } else {
        decimalSepparator = ".";
        decimalSepparator2Replace = ",";
    }

    //const getDecimalValue = function (input) {
    //    if (input.is("input")) {
    //        return input.val().toString().split(decimalSepparator2Replace).join(decimalSepparator);
    //    }

    //    return input.text().toString().split(decimalSepparator2Replace).join(decimalSepparator);

    //};

    $(".input-decimal").keypress(function (e) {
        const code = e.keyCode || e.which;

        if (code === 44 || code === 46) {
            const currentvalue = $(this).val() as string;
            return currentvalue.indexOf(decimalSepparator) === -1 && currentvalue.indexOf(decimalSepparator2Replace) === -1;
        }

        return (code > 47 && code < 58);
    });

    $(".input-decimal").on("input", function () {
        let valueString = $(this).val() as string;
        valueString = valueString.replaceAll(decimalSepparator2Replace, decimalSepparator);

        //valueString= this.replace(new RegExp(str, 'g'), newStr);

        const valueArr: Array<string> = [];
        const parts = valueString.split(decimalSepparator);

        for (const i in parts) {
            valueArr.push(parts[i].replace(/\D/g, ""));
        }
        $(this).val(valueArr.join(decimalSepparator));
        return false;
    });
});