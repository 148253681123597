//Todo
//https://www.tiny.cloud/docs/advanced/usage-with-module-loaders/webpack/webpack_es6_npm/

const initTynyMcePlaceHolders = (options, placeholders: string | any[]) => {
    //console.log("..", placeholders);
    options["setup"] = function (editor) {
        const buttons: any[] = [];
        for (let i = 0; i < placeholders.length; i++) {
            const name = placeholders[i];
            buttons.push({
                text: name,
                onclick: function () {
                    editor.insertContent("{" + this.settings.text + "}");
                }
            });
        }

        editor.addButton("placeholders", {
            type: "menubutton",
            text: "Placeholders",
            icon: false,
            menu: buttons
        });
    };
};

function getDefaultOptions(addidionaldata: any = null) {
    const options = {
        mode: "exact",
        menubar: false,
        statusbar: false,
        plugins: [
            "advlist autolink lists link image charmap anchor noneditable",
            "searchreplace visualblocks",
            "insertdatetime media contextmenu paste code bdesk_photo"
        ],
        resize: "both",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image code | placeholders",
        autoresize_max_height: "500px;"
    };

    if (addidionaldata) {
        if (addidionaldata.plugins) {
            options.plugins.push(addidionaldata.plugins);
        }

        if (addidionaldata.toolbar) {
            options.toolbar + addidionaldata.toolbar;
        }
    }

    if (window["defaultLanguage"]) {
        options["language"] = window["defaultLanguage"];
    }

    return options;
}

const Initialize = (): void => {
    // Todo: check if in memo panel

    if (window["tinymce"]) {
        $("textarea.richTextEditor").each(function () {
            const te = $(this);
            let placeholders = [];

            if (te.data("placeholders")) {
                placeholders = te.data("placeholders").split(",");
            }

            const o = getDefaultOptions();
            o["elements"] = te.attr("id");
            o["relative_urls"] = false;
            o["remove_script_host"] = false;
            o["convert_urls"] = false;

            if (!te.hasClass("hasCodeBtn")) {
                o.toolbar = o.toolbar.split(" code ").join(" ");
            }

            if (te.hasClass("inlineImage")) {
                o.toolbar = o.toolbar.replace(/\bimage\b/i, "bdesk_photo");
            } else {
                if (te.data("imageuploadaction")) {
                    o["images_upload_url"] = te.data("imageuploadaction");

                    o["init_instance_callback"] = function (ed) {
                        ed.execCommand("mceImage");
                    };
                }
            }

            const height: any = parseInt(te.css("height"));

            if (!isNaN(parseInt(height)) && height > 0) {

                o["height"] = te.css("height");
            } else {

                const rows: any = te.attr("rows");

                if (rows) {
                    o["height"] = rows * 26;
                }
            }

            if (te.is(":disabled") || te.is("[readonly]")) {
                o["readonly"] = 1;
                o["content_style"] = "body.mceContentBody { background-color: #F0F0F0; /*or backround: none */  }";
                //delete o.readonly;
            } else {
                delete o["readonly"];
            }

            o["init_instance_callback"] = function (inst) {
                inst.execCommand("mceAutoResize");
            };

            if (!te.attr("rows")) {
                o["height"] = "100%";

                o["setup"] = function (editor) {
                    initTynyMcePlaceHolders(o, placeholders);

                    editor.on("init", () => {
                        const ed = editor.targetElm;
                        setTimeout(() => { //wait for tinymce to load
                            const target = $(ed.targetElm);

                            const nano = target.closest(".nano-content");

                            if (nano.length) {
                                const nanoHeight = nano.height() as number;
                                $(ed.iframeElement).height(nanoHeight - 39);
                            }
                        }, 100);

                        $(window).resize(() => {
                            const target = $(ed.targetElm);
                            const iframe = $(ed.iframeElement);
                            const editor = $(ed.editorContainer);

                            iframe.css("display", "none");

                            setTimeout(() => {
                                const nano = target.closest(".nano-content");

                                const headerHeight = editor.find(".mce-toolbar-grp").height() as number;
                                //console.log('height', nano.height());

                                if (nano.length) {
                                    const nanoHeight = nano.height() as number;
                                    iframe.height(nanoHeight - (headerHeight + 8));
                                }

                                //$(ed.iframeElement).show();
                                iframe.css("display", "block");
                            }, 200);
                        });

                        //console.log('init event', e);
                    });
                };
            } else {
                initTynyMcePlaceHolders(o, placeholders);
            }

            o["body_class"] = "my_class";

            delete o["height"];
            window["tinymce"]["init"](o);
            //tinymce.init(o);
        });
    }
};

export const TinyMceManager = {
    Initialize
};