// -------------------------------------------------------------------------------------------------------------
// Helper for html/http
// -------------------------------------------------------------------------------------------------------------

const NotificationMessateType = {
    Succeeded: "success",
    Failed: "Error"
};

const ShowNotificationMessage = (message: any, type: string) => {
    const container = $("#NotificationContainer");
    const content = container.find(".containerContent");

    if (type === NotificationMessateType.Failed) {
        content.addClass("containerContent-error");
        content.removeClass("containerContent-success");
    } else {
        content.addClass("containerContent-success");
        content.removeClass("containerContent-error");
    }

    content.find(".caption").html(message);
    container.show();
};

const GetQueryStringValue = (name: string): string | null => {
    const results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);

    if (results == null) {
        return null;
    }

    return decodeURI(results[1]) || "";

};

const showSuccessNotificationMessage = (message: unknown): void => {
    ShowNotificationMessage(message, NotificationMessateType.Succeeded);
};

const showFailedNotificationMessage = (message: unknown): void => {
    ShowNotificationMessage(message, NotificationMessateType.Failed);
};

export const pageHelper = {
    NotificationMessateType,
    ShowNotificationMessage,
    GetQueryStringValue,
    showSuccessNotificationMessage,
    showFailedNotificationMessage
};