import { Breadcrumb } from "./BreadCrumbFunctions";
import { pageHelper } from "./pageHelper";
import { xhrManager } from "./xhrManager";
import { DateFunctions } from "./DateFunctions";
import { fileuploadControl } from "./FileUploadControl";
import { PanelSortFields, SideBar } from "./SidebarMenu";
import { TileScrollMenu } from "./TileScrollMenu";
import { PanZoom } from "panzoom";
import "./main";
import { GlobalSettings } from "./GlobalSettings";
import { EasylonStorageManager } from "./EasylonStorageManager";
import { FolderView } from "./FolderView";
import { TinyMceManager } from "./TinyMceManager";
import { createPaginationFooter, gm_authFailure, HandleRecaptcha, InitGoogleMaps, initPageBindings, InitReportComponent, ShowErrors, UpdateScrollBars } from "./GlobalFunctions";
import { ImageUploadFunctions } from "./ImageUploadFunctions";
import { Search } from "./SearchFunctions";
import { form, serlizeForm2Object } from "./FormFunctions";
import * as ko from "knockout";
import { CustomSerializedForm, PopupModal } from "./interfaces";
/*import "../LessCss/Site.less";*/
window.ko = window.knockout = ko;

export { };

interface ReCaptchaInstance {
    getResponse: (id: any) => any;
}
declare global {
    interface Window {
        easylon: unknown;
        GlobalSettings: unknown;
        pz: PanZoom | undefined,
        pzInstance: PanZoom | undefined,
        PanelSortFields: unknown;
        gm_authFailure: () => void;
        InitGoogleMaps: () => void;
        ImageUploadFunctions: unknown;
        ko: unknown;
        grecaptcha: ReCaptchaInstance
        knockout: unknown;
        serlizeForm2Object: (form: string) => CustomSerializedForm;
        popupModal: PopupModal;
    }

    interface JQuery {
        nanoScroller(options: any): any; //Todo: make npm version work
    }

    interface String {
        replaceAll(input: string, output: string): any;
    }
}

window.GlobalSettings = GlobalSettings;
window.PanelSortFields = PanelSortFields;
window.gm_authFailure = gm_authFailure;
window.InitGoogleMaps = InitGoogleMaps;
window.ImageUploadFunctions = ImageUploadFunctions;
window.serlizeForm2Object = serlizeForm2Object;

window.easylon = {
    Breadcrumb,
    pageHelper,
    xhrManager,
    DateFunctions,
    fileuploadControl,
    TileScrollMenu,
    SideBar,
    GlobalSettings,
    FolderView,
    Storage: EasylonStorageManager,
    TinyMceManager,
    InitReportComponent,
    ShowErrors,
    createPaginationFooter,
    Search,
    UpdateScrollBars,
    HandleRecaptcha,
    form
};

$(() => {
    initPageBindings();
});