// -------------------------------------------------------------------------------------------------------------
// Manager voor de XHR requests
// -------------------------------------------------------------------------------------------------------------
const xhrPool: Array<JQueryXHR> = [];

const Add = (jqXHR: JQueryXHR): void => {
    xhrPool.push(jqXHR);
};

const Remove = (jqXHR: JQueryXHR): void => {
    const i = xhrPool.indexOf(jqXHR);   //  get index for current connection completed
    if (i > -1) {
        xhrPool.splice(i, 1); //  removes from list by index
    }
};

const abortAll = (): void => {
    for (let i = 0; i < xhrPool.length; i++) {
        const jqXHR = xhrPool[i];

        if (jqXHR) {
            jqXHR.abort();
            xhrPool.splice(i, 1);
        }
    }
};

const Initialize = (): void => {
    $.ajaxSetup({
        beforeSend: function (jqXHR) {
            xhrManager.Add(jqXHR);
        }, //  annd connection to list
        complete: function (jqXHR) {
            xhrManager.Remove(jqXHR);
        }
    });
};

export const xhrManager = {
    Add,
    Remove,
    abortAll,
    Initialize
};