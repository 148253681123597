import { UpdateScrollBars } from "../GlobalFunctions";

$(() => {
    $("#NotificationContainer .icon-close").on("click", () => {
        $("#NotificationContainer").hide(0, () => {
            UpdateScrollBars();
        });
    });

    $(".alert-info").click(function () {
        $(this).hide(0);
    });
});
