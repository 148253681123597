import "nanoscroller";
import { Breadcrumb } from "./BreadCrumbFunctions";
import { EasylonStorageManager } from "./EasylonStorageManager";
import { PopupModal } from "./interfaces";
import { pageHelper } from "./pageHelper";
import { SideBar } from "./SidebarMenu";
import { TileScrollMenu } from "./TileScrollMenu";
import { TinyMceManager } from "./TinyMceManager";

export const HasOwnProperty = (obj: unknown, prop: string): boolean => {
    return Object.prototype.hasOwnProperty.call(obj, prop);
};

let scrollBarTimer: unknown;
let resizeInterval;
let lastClicked: HTMLElement | undefined;

export const UpdateScrollBars = (next?: () => void): void => {
    if (scrollBarTimer) {
        clearTimeout(scrollBarTimer as number);
    }

    const panelContainer = $(".panelContentContainer");
    const scrollers = panelContainer.find(".nano");

    scrollBarTimer = setTimeout(() => {
        if (scrollers.length) {
            scrollers.each(function () {
                const nano = $(this);

                if (nano.is(":visible")) {
                    if (nano.closest("metroTile")) {
                        if (!nano.height()) {
                            nano.height(nano.parent().height() || 0);
                        }
                    } else {
                        nano.height(panelContainer.height() || 0);
                    }

                    nano.nanoScroller({ destroy: true }).nanoScroller({ alwaysVisible: false });
                }
            });
        }

        if (next) {
            next();
        }
    }, 50);
};

export interface LoadEvent {
    (next: () => void): void;
}

export interface CallbackInterface {
    (): void;
}

const onLoadEvents: Array<LoadEvent> = [];

export const Onload = (func: LoadEvent): void => {
    onLoadEvents.push(func);
};

export const HandleJsonRequestError = (errors: string[]): void => {
    const headerErrorElement = $(".headerItem-error");
    const txtElement = headerErrorElement.find(".text");

    if (txtElement.length) {
        const messages: string[] = [];
        errors.forEach(element => messages.push(`<div>${element}</div>`));

        txtElement.html(messages.join());
        headerErrorElement.find(".close").hide();
        $(".headerItem-error").find(".alert").show(0);
        $(".headerItem-error").show(0);
    }
};

export const getUrlVars = (): { [name: string]: string | undefined; } => {
    return getUrlVarsFromUrl(window.location.href);
};

export const getUrlVarsFromUrl = (url: string): { [name: string]: string | undefined; } => {
    const vars: { [name: string]: string | undefined; } = {};
    const splitIndex = url.indexOf("?");

    if (splitIndex > -1) {
        const kvpLines = url.slice(splitIndex + 1).split("&");

        for (let i = 0; i < kvpLines.length; i++) {
            const kvpArr = kvpLines[i].split("=");

            if (kvpArr.length) {
                const value = kvpArr[0] as string;
                if (value) {
                    vars[value] = kvpArr.length > 1 ? kvpArr[1] : undefined;
                }
            }
        }
    }

    return vars;
};
export const ToggleSiteTop = (callback?: () => void): void => {
    const showBreadCrumbs = EasylonStorageManager.GetItem("ShowBreadCrumbs") === "false" ? false : true;
    const showTileBar = EasylonStorageManager.GetItem("ShowTileBar") === "false" ? false : true;

    function done() {
        if (callback) {
            callback();
        }
    }

    const breadCrumbContainer = $(".breadCrumbContainer");
    const topTileBarContainer = $(".topTileBarContainer");

    $(".fullPage").removeClass("fullPage-hasSubmenu");
    $(".fullPage").removeClass("fullPage-hasBreadCrumbs");
    $(".fullPage").removeClass("fullPage-hasTileBar");

    if (showBreadCrumbs && (topTileBarContainer.length && showTileBar)) {
        $(".fullPage").addClass("fullPage-hasSubmenu");
        breadCrumbContainer.show(0, () => {
            if (callback) {
                Breadcrumb.Update(callback);
            }
        });
        topTileBarContainer.show();
    } else if (showBreadCrumbs) {
        $(".fullPage").addClass("fullPage-hasBreadCrumbs");
        topTileBarContainer.hide();
        breadCrumbContainer.show(0, () => {
            if (callback) {
                Breadcrumb.Update(callback);
            }
        });
    } else if (topTileBarContainer.length && showTileBar) {
        $(".fullPage").addClass("fullPage-hasTileBar");
        breadCrumbContainer.hide(0, () => {
            topTileBarContainer.show(0, done);
        });
    } else {
        breadCrumbContainer.hide(0);
        topTileBarContainer.hide(0);
        done();
    }
};

export const HandleResize = (callback?: () => void) => {
    if (resizeInterval) {
        clearTimeout(resizeInterval);
    }

    const leftMenu = $(".leftMenu");
    if (leftMenu) {
        if (leftMenu.hasClass("leftMenu-maximized")) {
            EasylonStorageManager.Load();
            const state = EasylonStorageManager.GetItem("LeftMenuState");

            if (state && state === "leftMenu-minimized") {
                leftMenu.removeClass("leftMenu-maximized").addClass("leftMenu-minimized");
            }
        }

        if ((window.innerWidth <= 768)) {
            if (leftMenu.hasClass("leftMenu-maximized")) {
                $("#SideMenuOverlay").show(0);
            } else {
                $("#SideMenuOverlay").hide(0);
            }
        } else {
            $("#SideMenuOverlay").hide(0);
        }
    }

    resizeInterval = setTimeout(() => {
        SideBar.Update(null);
        UpdateScrollBars(undefined);
        ToggleSiteTop(undefined);
        Breadcrumb.Update(undefined);

        const selectedScroller = $(".tableRow-selected").find(".nano");

        if (selectedScroller.length) {
            selectedScroller.nanoScroller({ destroy: true }).nanoScroller({ alwaysVisible: true });
        }

        if (callback) {
            callback();
        }
    }, 200);
};

//Todo: fix recaptcha
export const HandleRecaptcha = (): void => {
    $("form").submit(() => {
        let errors = 0;

        $(".recaptcha").each(function () {
            const widgetId = $(this).data("widget-id");
            //const response = window['grecaptcha'].getResponse(widgetId);
            const response = window.grecaptcha.getResponse(widgetId);

            if (!response) {
                $("#recaptcha-val").show();
                errors++;
            } else {
                $("#recaptcha-val").hide();
            }
        });

        if (errors) {
            return false;
        }
    });
};

export const loadRss = (): void => {
    $(".rssFeed").each(function () {
        const container = $(this);
        const url = container.data("rss-url");
        const tpl = container.find(".rssFeedTpl");

        if (url && tpl) {
            tpl.remove();
            const nodata = $(this).find(".rssFeedNoData");
            nodata.hide(0);

            $.get(url, (data) => {
                for (let i = 0; i < data.Storingen.length; i++) {
                    const storing = data.Storingen[i];
                    const element = $(tpl[0].outerHTML);
                    element.find(".rssTtitle").text(storing["title"]).attr("href", storing["link"]);
                    const html = storing["description"]?.replace(new RegExp("\\r?\\n", "g"), "<br />");
                    element.find(".rssText").html((storing["date"] ? storing["date"] + " | " : "") + html);
                    container.append(element);
                    element.show();
                }

                if (!data.Storingen.length) {
                    nodata.show(0);
                }
            });
        }
    });
};

export const hideFloatingElements = (exclude?: JQuery, callback?: CallbackInterface): void => {
    let fe = $(".floatingElement, .dropdownMenu");

    if (exclude && exclude.length) {
        fe.removeClass("keepOpen");
        exclude.addClass("keepOpen");

        fe = fe.not(exclude);
    }

    const toCloseElements = fe.not(".keepOpen");

    if (toCloseElements.length) {
        fe.not(".keepOpen").fadeOut("fast", () => {
            fe.removeClass("keepOpen");

            if (callback) {
                callback();
            }
        });
    } else {
        fe.removeClass("keepOpen");

        if (callback) {
            callback();
        }
    }
};

function DisplayPage() {
    $("#Loader").fadeOut(0, () => {
        $("#SiteBody").fadeIn(0, () => {
            UpdateScrollBars(() => {
                //window['onhashchange'](null); //Todo: fix this
                //window.onhashchange({});
                TinyMceManager.Initialize();
                TileScrollMenu.Update();
            });

            setTimeout(() => {
                Breadcrumb.Update(undefined);
            }, 10);
        });
    });
}

export const InitializeApp = (): void => {
    const topTileBarContainer = $("#SiteTileScrollMenu");
    const tiles = topTileBarContainer.find(".tile");

    if (!tiles.length) {
        topTileBarContainer.remove();
        $(".topTileBarBtn").remove();
        ToggleSiteTop();
    }

    $(window).resize(() => {
        HandleResize();
    });

    HandleResize(DisplayPage);
};

export const InitReportComponent = (baseUrl: string): void => {
    let xhrRequest;
    let instance;
    const page = $(".page");
    let canZoom = false;

    const resetZoom = function () {
        if (instance) {
            instance.moveTo(0, 0);
            instance.zoomAbs(0, 0, 1);
            page.closest(".nano").nanoScroller({ scroll: "top" });
        }
    };

    const loadImage = function (url, completeCallback?) {
        if (xhrRequest) {
            xhrRequest.abort();
            xhrRequest = null;
        }

        xhrRequest = $.get(url, (data) => {
            if (data.Succeeded && data.Image) {
                $(".pageLoader").hide();
                ($(".loonstrookComponent .previewPane img")[0] as HTMLImageElement).src = data.Image;

                createPaginationFooter(data.PageCount, data.Page, baseUrl, (url, completeCb) => {
                    $(".pageLoader").show();
                    loadImage(url, completeCb);
                });
            } else {
                const headerErrorElement = $(".headerItem-error");
                const txtElement = headerErrorElement.find(".text");

                if (txtElement.length) {
                    const messages: Array<string> = [];
                    data.Errors.forEach(element => messages.push(`<div>${element}</div>`));

                    txtElement.html(messages.join());
                    headerErrorElement.find(".close").hide();
                    $(".headerItem-error").show();
                }
            }

            if (completeCallback) {
                completeCallback();
            }

            $(".pageLoader").hide();
        }, "json");
    };

    $(() => {
        $("body").addClass("body-loonstrook");
        const page = $(".loonstrookComponent").find(".page");

        $(".resetPanzoom").on("click", () => {
            if (instance) {
                resetZoom();
            }
        });

        $(".togglePanzoom").on("click", function (event) {
            $(".zoom-reset").hide(0);
            event.stopPropagation();
            canZoom = !canZoom;

            const btn = $(this);

            setTimeout(() => {
                if (canZoom) {
                    $(".disabledZoomIco").hide(0);
                    if (!instance) {
                        instance = window["panzoom"](page[0], {
                            maxZoom: 5,
                            minZoom: 1,
                            beforeWheel: function (e) {
                                const shouldIgnore = !e.altKey;
                                return shouldIgnore && !canZoom;
                            }
                        });

                        instance.on("zoom", () => {
                            var tranform = instance.getTransform();

                            if (tranform.scale != 1) {
                                $(".zoom-reset").show(0);
                            } else {
                                $(".zoom-reset").hide(0);
                            }
                        });
                    }

                    $(".page").closest(".nano").nanoScroller({ stop: true });
                    instance.resume();
                } else {
                    setTimeout(() => {
                        $(".disabledZoomIco").show(0);
                    }, 100);
                    resetZoom();
                    instance.pause();
                    $(".page").closest(".nano").nanoScroller({ stop: false });
                }
            }, 100);
        });

        loadImage(baseUrl);
    });
};

export function createPaginationFooter(totalPages: number, currentPage = 1, url: string, callback?: (url: string, cb: () => void) => void): void {
    if (!totalPages || totalPages === 1) {
        $("#SiteFooter").find(".footerMainContent-Pagination").remove();
        return;
    }

    lastClicked = undefined;
    const parts = url ? url?.split("?") : [];
    const baseUrl = parts.length ? parts[0] : "";

    const qs = (function (a) {
        if (!a || !a.length) {
            return {};
        }

        const b = {};
        for (let i = 0; i < a.length; ++i) {
            const p = a[i].split("=", 2);
            if (p.length == 1) {
                b[p[0]] = "";
            } else {
                b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
            }
        }
        return b;
    })(parts.length > 1 ? parts[1].split("&") : []);

    function generateUrl(base: string, queryParams: any = {}, updateParams: any = {}): string {
        const qq = { ...queryParams, ...updateParams };
        const keys = Object.keys(qq);

        if (keys.length) {
            const values: Array<string> = [];

            for (let i = 0; i < keys.length; i++) {
                if (keys[i]) {
                    values.push(keys[i] + "=" + qq[keys[i]]);
                }
            }

            //console.log("1>>", base + "?" + values.join("&"));
            return base + "?" + values.join("&");
        }

        //console.log("2>>", base);
        return base;
    }

    let footer = $("#SiteFooter");
    footer.remove();

    footer = $(`<div id="SiteFooter">
                            <div class="footerContainer"></div>
                        </div>`);
    $("#PageContentPane").append(footer);

    if (currentPage > totalPages) {
        currentPage = totalPages;
    } else if (currentPage < 1) {
        currentPage = 1;
    }

    let pagination = footer.find(".footerMainContent-Pagination");

    if (!pagination.length) {
        pagination = $("<div class=\"footerMainContent footerMainContent-Pagination\" />");
        footer.find(".footerContainer").append(pagination);
    }

    pagination.empty();

    const list = $("<ul class=\"pagination pagination-sm\" />");
    const hasPrevious = currentPage > 1;
    const hasNext = currentPage < totalPages;

    list.append($(`<li class="${hasPrevious ? "" : "disabled"}">
                    <a href="${generateUrl(baseUrl, qs, { page: 1, pageCount: totalPages })}">
                        <span class="fa fa-angle-double-left" aria-hidden="true"></span>
                    </a>
                </li>`));

    list.append($(`<li class="${hasPrevious ? "" : "disabled"}">
                    <a href="${generateUrl(baseUrl, qs, { page: currentPage - 1, pageCount: totalPages })}">
                        <span class="fa fa-angle-left" aria-hidden="true"></span>
                    </a>
                </li>`));

    const totalDigits = 5;
    const splitAmount = (totalDigits - 1) / 2;

    if (splitAmount > 0) {
        const availableLeft = ((currentPage - 1) - splitAmount) + splitAmount;
        const availableRigtht = totalPages - currentPage;

        if (availableLeft < availableRigtht) {
            const sizeLeft = availableLeft >= splitAmount ? splitAmount : availableLeft;

            const remaining = (splitAmount - sizeLeft) + splitAmount;
            const sizeRight = availableRigtht >= remaining ? remaining : availableRigtht;

            for (let i = sizeLeft; i > 0; i--) {
                const page = currentPage - i;

                list.append(`<li>
                                <a href="${generateUrl(baseUrl, qs, { page: page, pageCount: totalPages })}">${page}</a>
                            </li>`);
            }

            list.append(`<li class="active"><a href="#">${currentPage}</a></li>`);

            for (let i = 0; i < sizeRight; i++) {
                const index = i + 1;
                const page = currentPage + index;
                list.append(`<li>
                                <a href="${generateUrl(baseUrl, qs, { page: page, pageCount: totalPages })}">${page}</a>
                            </li>`);
            }
        } else {
            const sizeRight = availableRigtht >= splitAmount ? splitAmount : availableRigtht;
            const remaining = (splitAmount - sizeRight) + splitAmount;

            const sizeLeft = availableLeft >= remaining ? remaining : availableLeft;

            for (let i = sizeLeft; i > 0; i--) {
                const page = currentPage - i;
                list.append(`<li>
                                    <a href="${generateUrl(baseUrl, qs, { page: page, pageCount: totalPages })}">${page}</a>
                                </li>`);
            }

            list.append(`<li class="active"><a href="#">${currentPage}</a></li>`);

            for (let i = 0; i < sizeRight; i++) {
                const index = i + 1;
                const page = currentPage + index;
                list.append(`<li>
                                    <a href="${generateUrl(baseUrl, qs, { page: page, pageCount: totalPages })}">${page}</a>
                                </li>`);
            }
        }
    }

    //end listing

    const hasNextPage = currentPage < totalPages;
    const nextPage = hasNextPage ? currentPage + 1 : totalPages;

    list.append(`<li class="${hasNextPage ? "" : "disabled"}">
                        <a href="${generateUrl(baseUrl, qs, { page: nextPage, pageCount: totalPages })}"><span class="fa fa-angle-right" aria-hidden="true"></span></a>
                    </li>`);

    list.append(`<li class="${(currentPage + 1) <= totalPages ? "" : "disabled"}">
                        <a href="${generateUrl(baseUrl, qs, { page: totalPages, pageCount: totalPages })}">
                            <span class="fa fa-angle-double-right" aria-hidden="true"></span>
                        </a>
                     </li>`); totalPages;

    pagination.append(list);

    pagination.find("li a").on("click", function (event) {
        event.preventDefault();

        const li = $(this).closest("li");

        if (li.length && li.hasClass("disabled")) {
            return;
        }

        if (lastClicked) {
            return;
        }

        lastClicked = this;

        if (callback) {
            const url = $(this).attr("href") as string;
            callback(url, () => {
                lastClicked = undefined;
            });
        }
    });
}

export const ShowErrors = (errors: string[]): void => {
    if (errors && errors.length) {
        const container = $("<div />");

        errors.forEach(e => {
            container.append($(`<div>${e}</div>`));
        });

        const errorsHeader = $(".headerItem-error");

        if (errorsHeader.length) {
            const infoText = errorsHeader.find(".text");
            infoText.empty();
            infoText.append(container);

            errorsHeader.addClass("headerItem-Visible");
        }
    }
};

let popupModal: PopupModal;

export const showModal = (modal: PopupModal): boolean => {
    if (modal) {
        popupModal = modal;

        if (modal.Message) {
            const container = $("#PopOver");
            container.find(".alertMessage").text(modal.Message);

            $("#PopOver").show();
        }

        return true;
    }

    return false;
};

export const gm_authFailure = (): void => {
    $("[data-gmaddress]").each(function () {
        const container = $(this);
        $(container).html("<img src=\"/images/map.svg\" style=\"width:100%; height:100%; padding-top:5px;\" />");
    });
};

export const InitGoogleMaps = (): void => {
    $("[data-gmaddress]").each(function () {
        const container = $(this);

        container.on("click", function (event) {
            event.preventDefault();

            const link = $(this).closest("a.metroTile");

            if (link.length) {
                window.location.href = link.attr("href") as string;
            }
        });

        const adres = container.data("gmaddress");
        let map;

        const options = {
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            draggable: false,
            disableDefaultUI: true
        };

        const geocoder = new window["google"].maps.Geocoder();

        geocoder.geocode({ "address": adres }, (results, status) => {
            let center;

            if (status == window["google"].maps.GeocoderStatus.OK) {
                center = results[0].geometry.location;

                map = new window["google"].maps.Map(container[0], $.extend(options, {
                    center: results[0].geometry.location,
                    zoom: 12,
                    disableDefaultUI: true
                }));

                const marker = new window["google"].maps.Marker({
                    map: map,
                    position: results[0].geometry.location,
                    title: adres
                });
            } else {
                const location = { lat: 52.23, lng: 5.55 };
                center = location;

                map = new window["google"].maps.Map(container[0], $.extend(options, {
                    center: location,
                    zoom: 5,
                    disableDefaultUI: true
                }));

                const marker = new window["google"].maps.Marker({
                    map: map,
                    position: location,
                    title: "Geen adres"
                });
            }

            window["google"].maps.event.addListenerOnce(map, "idle", () => {
                window["google"].maps.event.trigger(map, "resize");
                map.setCenter(center);
            });
        });
    });
};

export const getInputValue = (input: JQuery): string => {
    return (input.val() as string) || "";
};

/* Onload bindings */
const bindImageDataSource = (): void => {
    if (!window.InitGoogleMaps) {
        window.InitGoogleMaps = InitGoogleMaps;
    }

    $("img[data-src]").each(function () {
        const imageElement = $(this);

        if (!imageElement) {
            return;
        }

        const src = imageElement.data("src");

        if (!src) {
            return;
        }

        const img = new Image();

        img.onload = function () {
            imageElement.attr("src", src);
        };

        img.src = src;
    });
};

const bingInfoPanelControls = (): void => {
    $(() => {
        $(".alert-info").each(function () {
            const infoRow = $(this);

            const formGroup = infoRow.closest(".form-group");

            formGroup
                .find(".input-group-info")
                .addClass("hasInfo").on("click", () => {
                    infoRow.toggle(0, () => {
                        if (UpdateScrollBars) {
                            UpdateScrollBars();
                        }
                    });
                });
        });

        $("#MainInfoContainer .alert-info").on("click", function (event) {
            //debugger
            event.stopPropagation();
            $(this).closest("#MainInfoContainer").hide(0, () => {
                if (UpdateScrollBars) {
                    UpdateScrollBars();
                }
            });
        });

        $(".alert .closeBtn").on("click", function (event) {
            event.preventDefault();
            event.stopPropagation();
            $(this).closest(".alert").hide();
        });

        const toggleButton = $(".toggleInfoBtn");

        toggleButton.on("click", function (event) {
            if ($(this).hasClass("toggleInfoBtn-main")) {
                const mainPanel = $("#MainInfoContainer");

                mainPanel.toggle(0, () => {
                    if (mainPanel.is(":visible")) {
                        mainPanel.find(".alert").show();
                        const iic = mainPanel.find(".innerInfoContainer");
                        const scrollableContent = mainPanel.find(".scrollableContent");

                        const infoPanelHeight = iic.height() as number;
                        const scollableContentHeight = scrollableContent.height() as number;

                        if (scollableContentHeight > infoPanelHeight) {
                            iic.css("height", infoPanelHeight);
                            iic.addClass("nano");
                        } else {
                            iic.removeClass("nano");
                        }
                    } else {
                        //mainPanel.find('.panelAlert').hide()
                    }
                });
            } else {
                const fv = $(this).closest(".folderview");
                if (fv.length) {
                    fv.next().toggle();
                    return;
                }

                event.preventDefault();
                $(".infoPanelContainer").first().toggle();
            }

            if (UpdateScrollBars) {
                UpdateScrollBars();
            }
        });

        $(".alert-info").each(function () {
            const alertInfo = $(this);
            let timerId;
            //debugger
            alertInfo.find(".close").on("click", (event) => {
                event.stopPropagation();
                clearTimeout(timerId);

                if (alertInfo.hasClass("panelAlert")) {
                    $("#MainInfoContainer").hide(0);
                }
            });
        });

        $(".panelAlert").each(function () {
            const alertInfo = $(this);
            let timerId;

            alertInfo.find(".close").on("click", function (event) {
                //debugger
                const alert = $(this).closest(".alert-info");
                if (alert.length) {
                    return;
                }

                event.stopPropagation();
                clearTimeout(timerId);

                $("#MainInfoContainer").hide(0, UpdateScrollBars);
            });
        });
    });
};

export const initPageBindings = (): void => {
    bingInfoPanelControls();
    bindImageDataSource();
};