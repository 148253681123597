import { pageHelper } from "../pageHelper";
import "./historyPanel";
import "./mainSearchPanel";
import "./breadCrumb";
import "./dropdown";
import "./invoerStaticPanel";
import "./popOver";
import "./sortableColumn";
import "./table";
import "./notifications";
import "./validation";
import { xhrManager } from "../xhrManager";
import { form } from "../FormFunctions";

$(() => {
    $("[data-gotourl]").on("click", function (event) {
        event.stopPropagation();
        window.location.href = $(this).data("gotourl");
    });

    $("[data-jsonurl]").on("click", function (event) {
        event.stopPropagation();
        const url = $(this).data("jsonurl");

        $.post(url, (response) => {
            if (response.Message) {
                if (response.Succeeded === true) {
                    pageHelper.showSuccessNotificationMessage(response.Message);
                    return;
                }

                if (response.Succudeed === false) {
                    pageHelper.showFailedNotificationMessage(response.Message);
                    return;
                }
            }
        });
    });

    $("body").on("click", () => {
        $(".dropdown-menu").hide();
    });

    $(".ui-autocomplete-input.postOnEnter").keyup(function (e) {

        if (e.keyCode == 13) {
            e.preventDefault();

            const input = $(this);

            if (input.val()) {
                $(".ui-menu-item").hide();
                input.prop("readonly", true);
                input.closest("form").submit();
            }
        }
    });

    $("a").on("click", form.onFormSubmit);

    $(".metroTile .menuBtn-withLink").on("click", function (e) {
        e.preventDefault();

        const url = $(this).data("href");

        if (url) {
            const win = window.open(url, "_blank");
            win?.focus();
        }
    });

    let ignorePageUnload = false;

    $("[data-download='true']").on("click", (event) => {
        event.stopPropagation();
        ignorePageUnload = true;
    });

    $(window).on("beforeunload", () => {
        if (!ignorePageUnload) {
            const container = $("#PopOver");
            container.empty().text(" ");
            container.show();
        }

        ignorePageUnload = false;
    });

    window.onbeforeunload = function () {
        xhrManager.abortAll();
        window.stop();

        //Todo: add remove on load
        if (window["loadingImages"]) {
            for (var i = 0; i < window["loadingImages"]; i++) {
                var image = window["loadingImages"][i];

                if (!image.complete) {
                    image.attr("src", "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEAAAAALAAAAAABAAEAAAI=;");
                }
            }
        }
    };
});

