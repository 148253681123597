const pending = {};
const defaultMinLenght = 3;

function Get(url: string, params: any): JQuery.jqXHR {
    let xhr = pending[url];

    if (xhr && xhr.readyState != 4) {
        xhr.abort();
        xhr = null;
    }

    xhr = $.post(url, params);
    pending[url] = xhr;

    return xhr;
}

function InitializeAutoComplete(searchFilterInput: any, url: string, minLength?): void {
    if (!minLength || !/^\d+$/.test(minLength)) {
        minLength = defaultMinLenght;
    }

    const requestObj = {};

    for (const i in searchFilterInput.data()) {
        const key = i.toLowerCase();
        if (key.length > 5 && key.substr(0, 5) == "param") {
            requestObj[key.substring(5)] = searchFilterInput.data(i);
        }
    }

    searchFilterInput.autocomplete({
        source: function (request, response) {
            //requestObj["term"] = request.term;
            const postData = {
                "term": request.term
            };

            var form = $(searchFilterInput).closest("form").not(searchFilterInput);
            var formArray = form.serializeArray();

            for (var i = 0; i < formArray.length; i++) {
                if (formArray[i].name.toLowerCase() !== "q") {
                    postData[formArray[i].name] = formArray[i].value;
                }
            }

            Get(url, postData)
                .done((data) => {
                    response(data);
                });
        },
        minLength: minLength,
        select: function (event, ui) {
            searchFilterInput.prop("disabled", true);
            window.location.href = ui.item.url;
        }
    });
}

function InitUserAccesAutoComplete(index: any, element: any) {
    var control = $(element);
    var alert = control.find(".alert-danger");
    alert.hide();

    var submitUrl = control.data("url");

    if (!submitUrl) {
        return;
    }

    var searchFilterInput: any = control.find("input.filterField");

    var url = "";

    if (searchFilterInput.length) {
        url = searchFilterInput.data("url");

        if (!url) {
            return;
        }
    } else {
        return;
    }

    var requestObj = {};

    searchFilterInput.autocomplete({
        source: function (request, response) {
            requestObj["term"] = request.term;
            Get(url, requestObj)
                .done((data) => {
                    response(data);
                });
        },
        minLength: defaultMinLenght
    });

    var submitBtn = control.find(".addAccessUserBtn");

    submitBtn.on("click", () => {
        alert.hide();
        var inputsAreValid = true;

        $.each(searchFilterInput, function () {
            var input = $(this);

            if (!input.val()) {
                //var messageTxt = alert.find('.messageTxt');
                //messageTxt.empty();
                //var li = $('<li />');
                //li.text("Niet alle velden zijn ingevuld");
                //alert.show();
                //messageTxt.append(li);
                inputsAreValid = false;
                return;
            }
        });

        if (!inputsAreValid) {
            return;
        }

        //if (!searchFilterInput.val() || searchFilterInput.prop('disabled')) {
        //    return;
        //}
        searchFilterInput.prop("disabled", true);

        var params = {};

        for (var attr in requestObj) {
            if (attr === "term") {
                continue;
            }

            params[attr] = requestObj[attr];
        }

        $.each(searchFilterInput, function () {
            var input = $(this);
            params[input.attr("name") as string] = input.val();
        });

        //params["userName"] = searchFilterInput.val();

        Get(submitUrl, params).done((response) => {

            if (response.Succeeded) {
                window.location.reload();
            } else {
                var messageTxt = alert.find(".messageTxt");
                messageTxt.empty();

                for (var i = 0; i < response.Errors.length; i++) {
                    var li = $("<li />");
                    li.text(response.Errors[0]);
                    messageTxt.append(li);
                }

                alert.show();
            }

            searchFilterInput.prop("disabled", false);
        });
    });
}

$(() => {
    $(".userAccessControll").each(InitUserAccesAutoComplete);

    $.each($("#q, .autocomplete"), function () {
        var searchFilterInput = $(this);

        if (searchFilterInput.length) {
            var url = searchFilterInput.data("url");
            Search.InitializeAutoComplete(searchFilterInput, url);
        }
    });
});

export const Search = {
    Get,
    InitializeAutoComplete
};