import "jquery-ui";
import { GlobalSettings } from "./GlobalSettings";
import moment from "moment";

const ParseDate = (dateString: string): Date | undefined => {
    if (GlobalSettings.DateValidationFormat && dateString) {
        const parsedDate = moment(dateString, GlobalSettings.DateValidationFormat);

        if (parsedDate.isValid()) {
            return parsedDate.toDate();
        }
    }

    return undefined;
};

const IsDate = (obj: unknown): boolean => {
    return Object.prototype.toString.call(obj) === "[object Date]";
};

const CalculeerLeeftijd = (geboortedatum: string): number | undefined => {
    let date = ParseDate(geboortedatum);
    const currentDate = new Date();

    if (!date) {
        date = currentDate;
    }

    const year = 24 * 60 * 60 * 1000 * 365.25;
    const leeftijd = Math.floor((currentDate.valueOf() - date.valueOf()) / year);

    if (leeftijd > 0) {
        return leeftijd;
    }
    return undefined;

};

const CalculeerDienstjaren = (startDienstverband: string, eindeDienstverband: string): number | undefined  => {
    let date1 = ParseDate(startDienstverband);
    let date2 = ParseDate(eindeDienstverband);
    const currentDate = new Date();

    if (!date1) {
        date1 = currentDate;
    }

    if (!date2) {
        date2 = currentDate;
    }

    const datediff = date2.valueOf() - date1.valueOf();
    const year = 24 * 60 * 60 * 1000 * 365.25;

    if (datediff > 0) {
        return Math.floor(datediff / year);
    }
    return undefined;

};

$(() => {
    $("input[type=date], input[type=datetime], .datepicker").datepicker({
        dateFormat: GlobalSettings.DatePickerFormat || "",
        onSelect: function (dateText: string, inst: { input: HTMLElement }) {
            $(inst.input).trigger("change");
        }
    }).each( (index, item: HTMLElement): false | void => {
        if ($(item).attr("readonly")) {
            $(item).unbind("focus");
        }
    });

    if ($.validator && $.validator.methods) {

        $.validator.methods["date"] = function (value: unknown, element: HTMLElement): boolean {
            const dateString = $(element).val();
            const isValidDate = dateString && GlobalSettings.DateValidationFormat ? moment(dateString, GlobalSettings.DateValidationFormat).isValid() : false;

            if (this.optional(element) || (GlobalSettings.DateValidationFormat && isValidDate)) {
                return true;
            }

            return false;
        };
    }
});

export const DateFunctions = {
    ParseDate,
    IsDate,
    CalculeerLeeftijd,
    CalculeerDienstjaren
};