import "jquery-jcrop";

const controls = {};
let controlName;
let mainControl;

function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" +
        s4() + "-" + s4() + s4() + s4();
}

function ReduceImage(img: HTMLImageElement, maxSize: number): HTMLImageElement {
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    if (img.width > img.height) {
        if (img.width > maxSize) {
            canvas.width = maxSize;
            canvas.height = Math.round((maxSize * img.height) / img.width);
        } else {
            canvas.width = Math.round(img.width);
            canvas.height = Math.round(img.height);
        }
    } else {
        if (img.height > maxSize) {
            canvas.height = maxSize;
            canvas.width = Math.round((maxSize * img.width) / img.height);
        } else {
            canvas.width = Math.round(img.width);
            canvas.height = Math.round(img.height);
        }
    }

    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

    const image = new Image();
    image.id = "pic";
    image.src = canvas.toDataURL();

    return image;
}

function bindInputControl(input) {
    $(input).change(function () {
        $("#" + controlName + "_X").val('');
        $("#" + controlName + "_Y").val('');
        $("#" + controlName + "_Width").val('');
        $("#" + controlName + "_Height").val('');
        $(".imageUploadControl").find("img").attr("src", '');

        readURL(this, mainControl);
    });
}

function base64toBlob(base64Data: string) {
    const parts = base64Data.split(',') as string[];
    const contentType = parts[0].split(':')[1].split(';')[0];
    const sliceSize = 1024;
    const byteCharacters = atob(parts[parts.length - 1]);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    return new Blob(byteArrays, { type: contentType });
}

const initialize = (): void => {
    $(() => {
        $.each($(".imageUploadControl"), function () {
            const self = $(this);
            let id = self.attr("id");
            self.data("initialized", "true");

            const img = self.find("img");

            if (!id) {
                id = guid();
                self.attr("id", id);
            }

            controlName = id.substr(0, id.length - "thumbnail".length);

            const control = {
                currentImage: new Image(),
                id: id,
                imageDeleteControl: "#" + controlName + "ImageDelete",
                jcrop_api: undefined as any
            };

            control.currentImage.src = img.first().attr("src") as string;
            controls[id] = control;

            mainControl = control;

            $(self.find("input:file")).change(function () {
                $("#" + controlName + "_X").val('');
                $("#" + controlName + "_Y").val('');
                $("#" + controlName + "_Width").val('');
                $("#" + controlName + "_Height").val('');
                img.attr("src", '');
                readURL(this, control);
            });

            self.find(".deleteBtn").on("click", function () {
                const btn = $(this);

                if (control.jcrop_api) {
                    control.jcrop_api.destroy();
                    control['jcrop_api'] = null;
                }

                $(control.imageDeleteControl).val("true");

                $("#" + controlName + "_X").val('');
                $("#" + controlName + "_Y").val('');
                $("#" + controlName + "_Width").val('');
                $("#" + controlName + "_Height").val('');
                self.find("img").attr("src", '');

                btn.hide();
            });

            if (!img || !img.attr("src")) {
                img.hide();
                self.find(".deleteBtn").hide();
                return true;
            }

            setTimeout(() => {
                const heightField = $("#" + controlName + "_Height");
                const yField = $("#" + controlName + "_Y");

                const widthField = $("#" + controlName + "_Width");
                const xField = $("#" + controlName + "_X");

                const x2 = parseInt(widthField.val() as string) + parseInt(xField.val() as string);
                const y2 = parseInt(heightField.val() as string) + parseInt(yField.val() as string);

                img.Jcrop({
                    aspectRatio: 1,
                    onRelease: function () {
                        onCoordRelease(control);
                    },
                    onSelect: function (c) {
                        onCoordSelect(control, c);
                    },
                    boxHeight: 176,
                    boxWidth: 476,
                    setSelect: [$("#" + controlName + "_X").val() as number, $("#" + controlName + "_Y").val() as number, x2, y2],
                }, function (this: JQuery.Jcrop.Api): void {
                    control['jcrop_api'] = this;
                });
            }, 400);
        });
    });
};

function readURL(input, control) {
    if (input.files && input.files[0]) {
        if (control['jcrop_api']) {
            control['jcrop_api'].destroy();
            control['jcrop_api'] = null;
        }

        const reader = new FileReader();
        reader.onload = function (e) {
            const previewImage = $("#" + control.id + " img");

            if (control['jcrop_api']) {
                control['jcrop_api'].destroy();
                control['jcrop_api'] = null;
            }

            //previewImage.attr("src", e?.target?.["result"] as string);
            //previewImage.css("width", "auto");
            //previewImage.css("height", "100%");

            const image = new Image();
            image.src = e?.target?.["result"] as string;

            image.onload = function () {
                if (control['jcrop_api']) {
                    control['jcrop_api'].destroy();
                    control['jcrop_api'] = null;
                }

                const resizedImage = ReduceImage(image, 512);

                $(resizedImage).on("load", () => {
                    control.currentImage = resizedImage;

                    const currentInput = $("input[type=file]");
                    const input = document.createElement("input");
                    input.type = 'file';
                    input.id = currentInput.attr('id') as string;
                    input.name = currentInput.attr('name') as string;
                    input.accept = currentInput.attr('accept') as string;
                    $(input).attr('style', currentInput.attr('style') as string);
                    const dt = new ClipboardEvent('').clipboardData || new DataTransfer();
                    dt.items.add(new File([base64toBlob(resizedImage.src)], image.name, { type: 'image/png' }));
                    input.files = dt.files;
                    $("input[type=file]").replaceWith(input);

                    bindInputControl(input);

                    previewImage.attr('src', resizedImage.src);

                    setProperties(control);
                });
            };
        };

        reader.readAsDataURL(input.files[0]);
    }
}

function onCoordRelease(control) {
    setProperties(control);
}

function onCoordSelect(control, c) {
    controlName = control.id.substr(0, control.id.length - 9);

    $("#" + controlName + "_X").val(parseInt(c.x));
    $("#" + controlName + "_Y").val(parseInt(c.y));
    $("#" + controlName + "_Width").val(parseInt(c.w));
    $("#" + controlName + "_Height").val(parseInt(c.h));
}

function setProperties(control) {
    setTimeout(() => {
        if (control['jcrop_api']) {
            control['jcrop_api'].destroy();
            control['jcrop_api'] = null;
        }

        const jqImage = $("#" + control.id + " img");
        //jqImage.show();
        const image = jqImage[0];
        let size;
        let top = 0;
        let left = 0;
        const width = Math.round(control.currentImage ? control.currentImage.width : $(image).width());
        const height = Math.round(control.currentImage ? control.currentImage.height : $(image).height());

        if (width > height) {
            size = height;
        } else {
            size = width;
        }

        top = Math.round((height - size) / 2);
        left = Math.round((width - size) / 2);

        if (control['jcrop_api']) {
            control['jcrop_api'].destroy();
            control['jcrop_api'] = null;
        }

        jqImage.Jcrop({
            setSelect: [left, top, size, size],
            aspectRatio: 1,
            onRelease: function () {
                onCoordRelease(control);
            },
            onSelect: function (c) {
                onCoordSelect(control, c);
            },
            boxHeight: 176,
            boxWidth: 476,
            trueSize: [width, height]
        }, function (this: JQuery.Jcrop.Api): void {
            control['jcrop_api'] = this;
        });

        $(control.imageDeleteControl).val("false");
        jqImage.closest(".imageUploadControl").find(".deleteBtn").show();
    }, 200);
}

$(() => {
    initialize();

    //$(window).resize(() => {
    //    $.each(controls, () => {
    //        setProperties(this);
    //    });
    //});
});

export const ImageUploadFunctions = {
    initialize
};