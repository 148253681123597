import Panzoom, { PanZoom } from "panzoom";
const defaultZoom = 1;

const Update = (): void => {
    const fv = $(".folderview");

    if (fv.length) {
        const folderview = fv.first();
        const parent = folderview.closest("li");

        const fl = parent.find("> .filesList");

        fl.show(0, () => {
            const fa = folderview.find(".togglePanelBtn > .fa");
            fa.attr("class", "fa fa-chevron-up");
            fl.find(".fileName").click();
        });
    }
};

const InitLoonstrookComponent = (): void => {

    let loonstrookComponent = $(".loonstrookComponent");

    $("body").addClass("body-loonstrook");

    loonstrookComponent.find(".toggleBtn").on("click", () => {
        loonstrookComponent.toggleClass("loonstrookComponent-expanded");
    });

    if (!loonstrookComponent) {
        loonstrookComponent = $(".loonstrookComponent");
    }

    const bullets = loonstrookComponent.find(".bullet");

    bullets.on("click", function () {
        LoadImage($(this));
    });

    if (bullets.length) {
        LoadImage(bullets.first());
    }

    if (bullets.length <= 1) {
        $(".previewPaneNav").hide();
    }

    const page = $(".loonstrookComponent").find(".page");
    let pz: PanZoom | undefined = undefined;

    $(".zoomToogle").on("click", function () {
        if ($(this).hasClass("zoomToogle-enabled")) {
            $(this).removeClass("zoomToogle-enabled");

            if (pz) {
                pz.zoomAbs(
                    0, // initial x position
                    0, // initial y position
                    1  // initial zoom
                );

                pz.pause();
            }
        } else {
            $(this).addClass("zoomToogle-enabled");

            if (pz) {
                pz.resume();
            } else {
                pz = initPanzoom(page);
            }
        }
    });
};

const initPanzoom = (page: JQuery): PanZoom | undefined => {

    const instance = Panzoom(page[0], {
        maxZoom: 4,
        minZoom: 1,
        autocenter: true,
        bounds: true,
        smoothScroll: false,
        zoomSpeed: 0.02
    });

    setTimeout(() => {
        if (!instance.zoomAbs) {
            return;
        }

        instance.zoomAbs(
            0, // initial x position
            0, // initial y position
            1  // initial zoom
        );
    }, 200);

    instance.on("zoom", () => {
        const transformdata = instance.getTransform();

        if (transformdata.scale <= 1) {
            instance.moveTo(0, 0);
        }
    });

    page.removeClass("canSwipe-ignore");
    window.pzInstance = instance;
    return instance;
};

function loadImage(url, img) {
    const loader = $("<img />");

    loader.one("load", () => {
        img.replaceWith(loader);
    });

    if (!window["loadingImages"]) {
        window["loadingImages"] = [];
    }

    window["loadingImages"].push(loader);
    loader.attr("src", url);
}

const LoadImage = (bullet: JQuery): void => {

    if (bullet.hasClass("bullet-selected")) {
        return;
    }

    const url = bullet.data("url");
    const pages = bullet.data("pages") || 1;
    const page = $(".loonstrookComponent").find(".page");
    page.html("");

    if (!url || !pages) {
        return;
    }

    const loonstrookComponent = $(".loonstrookComponent");
    const bullets = loonstrookComponent.find(".bullet");
    bullets.removeClass("bullet-selected");
    bullet.addClass("bullet-selected");

    window.pz = initPanzoom(page);

    for (let i = 0; i < pages; i++) {
        const img = $("<img src=\"/Images/no-preview.png\" data-id=\"" + i + "\" />");
        page.append(img);
        loadImage(url + "&pageIndex=" + i, img);
    }
};

const LoadPreview = (container: JQuery): void => {
    const url = container.data("url");
    const page = $(".pageContainer").find(".page");
    page.html("");

    if (!url) {
        return;
    }

    window.pz = initPanzoom(page);

    const img = $("<img src=\"/Images/no-preview.png\" data-id=\"0\" />");
    page.append(img);
    loadImage(url, img);

    /* nav */

    if ($(container).hasClass("file-select")) {
        const count = $(container).data("pages");

        const nav = $(".pageNavigation");
        nav.empty();

        for (let i = 0; i < count; i++) {
            const div = $("<div class=\"navBullet\">" + (i + 1) + "</div>");
            div.data("url", container.data("url") + "&pageIndex=" + i);
            nav.append(div);

            div.on("click", function () {
                $(this).siblings().removeClass("selected");
                $(this).addClass("selected");
                LoadPreview($(this));
            });
        }

        if (!nav.find(".selected").length) {
            nav.find("div").first().addClass("selected");
        }
    }
};


export const FolderView = {
    Update,
    InitLoonstrookComponent,
    initPanzoom,
    LoadImage,
    LoadPreview
};

$(() => {
    $(".fileExplorer .folderToggle").on("click", function () {
        const btn = $(this);
        const subfolder = btn.closest(".explorerItem").find(".subfolders").first();

        subfolder.toggle(0, () => {
            const fa = btn.find(".fa");

            if (subfolder.is(":visible")) {
                fa.removeClass("fa-folder").addClass("fa-folder-open");
            } else {
                fa.removeClass("fa-folder-open").addClass("fa-folder");
            }
        });
    });

    $(".file-select").on("click", function () {
        $(".file-select").removeClass("file-selectHighlight");
        $(this).addClass("file-selectHighlight");
        LoadPreview($(this));
    });

    $(".fileExplorer .icon-right.togglePanelBtn").on("click", function () {
        const btn = $(this);

        const li = btn.closest("li");
        li.find(".filesList").first().toggle(0, function () {
            const visible = $(this).is(":visible");
            const fa = btn.find(".fa");

            if (visible) {
                fa.attr("class", "fa fa-chevron-up");
            } else {
                fa.attr("class", "fa fa-chevron-down");
            }
        });
    });

    $(".togglePreview").on("click", function () {
        const root = $(this).closest(".fileExplorer");
        const preview = root.find(".preview");
        const url = $(this).data("url");
        const img = preview.find("img")[0];

        if (preview.is(":visible")) {
            if (preview.data("url") != url) {
                const tmp = new Image();

                tmp["src"] = url;
                tmp.onload = function () {
                    img["src"] = url;
                };

                preview.data("url", url);
                root.find(".fePane").addClass("fePane-withPreview");
                preview.show(0);
            } else {
                preview.hide(0);
                root.find(".fePane").removeClass("fePane-withPreview");
            }
        } else {
            const tmp = new Image();

            tmp["src"] = url;
            tmp.onload = function () {
                img["src"] = url;
            };

            preview.data("url", url);
            root.find(".fePane").addClass("fePane-withPreview");
            preview.show(0);
        }
    });

    $(".resetPanzoom").on("click", () => {
        if (window["pz"] && window["pz"].zoomAbs) {
            window["pz"].zoomAbs(
                0, // initial x position
                0, // initial y position
                1  // initial zoom
            );
        }
    });

    Update();
});