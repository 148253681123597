$(() => {
    $("#PopOver .OkButton").on("click", () => {
        const popupModal = window.popupModal;
        if (popupModal && popupModal.Url) {
            window.location.href = popupModal.Url;
        } else {
            $("#PopOver").hide();
        }
    });

    $("#PopOver .OkCancelButton").on("click", () => {
        $("#PopOver").hide();
    });
});

