export enum PopupModalType {
    Ok,
    OkCancel
}

export interface PopupModal {
    Title?: string;
    Message: string;
    Url?: string;
    Type: PopupModalType;
}

export interface CustomSerializedForm {
    Fields: { [name: string]: string },
    Count: () => void;
}