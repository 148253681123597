import { hideFloatingElements } from "../GlobalFunctions";

$(() => {
    $(".dropdown-toggle").on("click", function (event) {
        event.stopPropagation();

        $(this).siblings(".dropdown-menu").first().toggle();
    });

    $(".dropdown-menu").on("click", function (event) {
        $(this).hide();
    });

    $(".listDropdown").each(function () {
        const element = $(this);
        const name = element.data("name");

        const filter = element.find("input[name=filter]");

        filter.on("input", function () {
            //console.log("change....");

            const word = $(this).val();

            const cbs = element.find(".enumValue").not(".toggleAll");

            if (word) {
                cbs.each(function () {
                    const value = $(this).val();
                    const li = $(this).closest("li");

                    if (value && (value as string).toLowerCase().indexOf((word as string).toLowerCase()) !== -1) {
                        li.show();
                    } else {
                        li.hide();
                    }
                });
            } else {
                cbs.closest("li").show();
            }
        });

        function setRolesValues() {
            var checked = element.find(".enumValue:checked").not(".toggleAll");
            var arr: any[] = [];

            var name = element.data("filter");

            if (!name) {
                name = "Roles";
            }

            checked.each(function () {
                arr.push($(this).val());
            });

            element.find("input[name=" + name + "]").val(arr.join(","));
        }

        element.find(".toggleAll").change(function (event) {
            if ($(this).is(":checked")) {
                element.find(".enumValue").prop("checked", true);
            } else {
                element.find(".enumValue").prop("checked", false);
            }

            let value = 0;

            element.find(".enumValue[type=checkbox]:checked").each(function (event) {
                value = value | parseInt($(this).val() as string);
            });

            element.find("[name=\"" + name + "\"]").val(value);
            setRolesValues();
            element.find(".enumValue").attr("disabled", "true");
            element.closest("#MainPanelSearch").find("form").submit();
        });

        element.find(".enumValue").change(() => {
            let value = 0;
            element.find(".enumValue[type=checkbox]:checked").each(function (event) {
                value = value | parseInt($(this).val() as string);
            });

            element.find("[name=\"" + name + "\"]").val(value);
            setRolesValues();
            element.find(".enumValue").attr("disabled", "true");
            return;
        });
    });

    $(".enumDropdown").each(function () {
        const element = $(this);
        const name = element.data("name");

        element.find(".toggleAll").change(function (event) {
            if ($(this).is(":checked")) {
                element.find(".enumValue").prop("checked", true);
            } else {
                element.find(".enumValue").prop("checked", false);
            }

            let value = 0;

            element.find(".enumValue[type=checkbox]:checked").each(function (event) {
                value = value | parseInt($(this).val() as string);
            });

            element.find("[name=\"" + name + "\"]").val(value);
            element.closest("#MainPanelSearch").find("form").submit();
        });

        element.find(".enumValue").change(() => {
            let value = 0;
            element.find(".enumValue[type=checkbox]:checked").each(function (event) {
                value = value | parseInt($(this).val() as string);
            });

            element.find("[name=\"" + name + "\"]").val(value);
        });
    });

    $(".dropdownHeaderComponent .list").find(".toggleBtn").on("click", function (event) {
        event.preventDefault();

        $(this).closest(".list").toggleClass("list-expanded");
    });

    $(".dropdownBtnToggleBtn").on("click", function (event) {
        var dropdown = $(this).find(".dropdownMenu");

        var isVisible = dropdown.is(":visible");

        hideFloatingElements(dropdown, () => {
            if (!isVisible) {
                dropdown.fadeIn("fast");
            }
        });
    });
});