let menu;
let container;
let movable;

const IsTileVisible = (tile, offset: number, containerWidth: number): string => {
    const visiblePosition: number = tile.position().left + offset;
    const visiblePositionOuter: number = (visiblePosition + tile.width());

    const isLeftVisible: boolean = visiblePosition >= 0 && visiblePosition < containerWidth;
    const isRightVisible: boolean = visiblePositionOuter >= 0 && visiblePositionOuter < containerWidth;

    if (isLeftVisible && isRightVisible) {
        return "full";
    } else if (isLeftVisible || isRightVisible) {
        return "partial";
    }

    return "no";

};

const getMenu = () => {
    menu = menu || $("#SiteTileScrollMenu");
    return menu;
};

const getMovable = () => {
    movable = movable || getMenu().find(".movable");
    return movable;
};

const getContainer = () => {
    container = container || getMenu().find(".scrollable");
    return container;
};

const canScrollLeft = (): boolean => {
    const pos = getMovable().data("posx") || 0;
    return (getContainer().width() - (getMovable().width() + pos)) < 0;
};

const canScrollRight = (): boolean => {
    const pos = getMovable().data("posx") || 0;
    return pos < 0;
};

const scrollLeft = (button: HTMLElement): void => {

    const parent = $(button).parent();
    const scrollable = parent.find(".scrollable");
    const movable = parent.find(".movable");
    const pos = movable.data("posx") || 0;
    let newPos;

    const tiles = movable.find(".tile");

    for (let i = 0; i < tiles.length; i++) {
        const tile = $(tiles[i]);

        const isVisible = IsTileVisible(tile, pos, scrollable.width() as number);
        if (isVisible === "partial" || isVisible === "no") {
            newPos = tile.position().left * -1;
            break;
        }
    }

    if (movable && scrollable) {
        if ((movable.width() + newPos) < (scrollable.width() as number)) {
            newPos = ((movable.width() as number) - (scrollable.width() as number)) * -1;
        }

        movable.animate({ left: newPos });
        movable.data("posx", newPos);
        TileScrollMenu.Update();
    }

};

const scrollRight = (button: HTMLElement): void => {
    const parent = $(button).parent();
    const scrollable = parent.find(".scrollable");
    const pos = movable.data("posx") || 0;
    let newPos;

    const tiles = movable.find(".tile");

    const startPos = (tiles.length - 1);

    for (let i = 0; i < tiles.length; i++) {
        const tile = $(tiles[startPos - i]);

        const isVisible = IsTileVisible(tile, pos, scrollable.width() as number);

        if (isVisible === "partial") {
            break;
        }
    }

    if (newPos === null) {
        newPos = pos + 200;
        newPos = newPos > 0 ? 0 : newPos;
    }

    movable.animate({ left: newPos });
    movable.data("posx", newPos);
    TileScrollMenu.Update();
};

const scrollToSelected = (): void => {
    const selected = container.find("a.selected");
    const newPos = 0;

    if (selected.length) {
        const currentPos = getMovable().data("posx") || 0;
        const scrollableTileArea = getMenu().find(".scrollableTileArea");
        const visibility = IsTileVisible($(selected[0]), currentPos, getContainer().width());
    }

    movable.css("left", newPos);
    movable.data("posx", newPos);
    TileScrollMenu.Update();
};

const Update = (): void => {
    //var pos = movable.data('posx') || 0;

    //if (pos < 0) {
    //    alert('go right');
    //}

    const leftArrow = getMenu().find(".arrow-left");
    if (canScrollRight()) {
        leftArrow.addClass("arrow-clickable");
        leftArrow.show();
    } else {
        leftArrow.removeClass("arrow-clickable");
        leftArrow.hide();
    }

    const rightArrow = getMenu().find(".arrow-right");
    if (canScrollLeft()) {
        rightArrow.addClass("arrow-clickable");
        rightArrow.show();
    } else {
        rightArrow.removeClass("arrow-clickable");
        rightArrow.hide();
    }
};

$(() => {

    $(window).resize(() => {
        movable.animate({ left: 0 });
        movable.data("posx", 0);
    });

    setTimeout(() => {
        $("#SiteTileScrollMenu").find(".arrow").on("click", function (e: JQueryEventObject) {

            const btn = $(e.target);

            if (!btn.hasClass("arrow-clickable")) {
                return;
            }


            if (btn.hasClass("arrow-left")) {
                scrollRight(btn[0] as HTMLElement);
            } else if ($(this).hasClass("arrow-right")) {
                scrollLeft(btn[0] as HTMLElement);
            }
        });

        Update();
    }, 50);
});

export const TileScrollMenu = {
    canScrollLeft,
    canScrollRight,
    scrollLeft,
    scrollRight,
    scrollToSelected,
    Update
};