import { CallbackInterface } from "./GlobalFunctions";
import { TileScrollMenu } from "./TileScrollMenu";

export const PanelSortFields = {
    Name: "sortByName",
    Number: "sortByNumber"
};

//let scrollBarTimer;
//let selectedRow;
const sortProperty = "sortOrder";

const UpdateScrollBars = (callback?: CallbackInterface) => {
    if (callback) {
        callback();
    }
};

//Todo: check if needed
//export function UpdateScrollBars2(callback?: CallbackInterface) {
//    if (scrollBarTimer) {
//        clearTimeout(scrollBarTimer);
//    }

//    var scrollers = $("#SideMenuTable").find(".nano");
//    scrollers.css('height', 'auto');

//    scrollBarTimer = setTimeout(function () {
//        scrollers.each(function () {
//            var nano = $(this);
//            nano.height(nano.parent().height());

//            nano.find('.scrollableContent').height(nano.parent().height());
//        });

//        if (scrollers.length) {
//            scrollers.each(function () {
//                var nano = $(this);

//                nano.nanoScroller({ destroy: true }).nanoScroller({ alwaysVisible: false })
//                    .find('.nano-pane').each(function () {
//                        var scollable = $(this).closest('.nano').find('.scrollableContent');

//                        if ($(this).is(':visible')) {
//                            scollable.addClass("scrollableContent-withScroll");
//                        }
//                        else {
//                            scollable.removeClass("scrollableContent-withScroll");
//                        }
//                    });

//                nano.find("li.selected").each(function () {
//                    $(this).closest('.nano').nanoScroller({ scrollTo: $(this), alwaysVisible: true });
//                });
//            });
//        }

//        if (callback) {
//            callback();
//        }
//    }, 50);
//}

const SelectListItem = (tableRow: any): void => {
    const rows = $("div.tableRow");
    let collapsableRows = rows.not(".tableRow-fixed");
    collapsableRows.addClass("tableRow-collapsed");

    if (!tableRow) {
        tableRow = collapsableRows.last();
    }

    if (tableRow) {
        collapsableRows = collapsableRows.not(tableRow);
        tableRow.removeClass("tableRow-collapsed").addClass("tableRow-selected");
        collapsableRows.removeClass("tableRow-selected");

        tableRow.find(".headerImage").hide();
        tableRow.find(".headerIcon").show();
        tableRow.find(".titleText a").text(tableRow.data("title"));
        const nano = tableRow.find(".nano");

        if (nano.length) {
            tableRow.find(".nano").nanoScroller({ alwaysVisible: true });
        }
    }

    collapsableRows.each(function () {
        const row = $(this);

        const headerIcon = row.find(".headerIcon");
        const headerImage = row.find(".headerImage");
        const header = row.find(".tableCellHeader");

        const sortMethod = window["PanelDefaultSortField"] || PanelSortFields.Name;

        function hideImage() {
            headerImage.hide(0, () => {
                headerIcon.show(0);
            });
        }

        if (row.hasClass("tableRow-collapsed")) {
            const selected = row.find("li.selected");

            if (row.hasClass("tableRow-list") && selected.length) {
                row.find(".titleText").find("a").text(selected.find(".textColumn").text());

                const logoImage = selected.data("logo");

                if (logoImage) {
                    headerImage.attr("src", logoImage);
                    headerImage.one("load", () => {
                        headerIcon.hide(0, () => {
                            headerImage.show(0);
                        });
                    });
                }

                header.find("a").attr("href", selected?.find("a")?.attr("href") as string);
            } else {
                hideImage();
                row.find(".titleText").find("a").text(row.data("title"));
                header.find("a").attr("href", row.data("url"));
            }
        } else {
            hideImage();
            row.find(".titleText").find("a").text(row.data("title"));
            header.find("a").attr("href", row.data("url"));

            if (tableRow) {
                if (!tableRow.data(sortProperty)) {
                    SideBar[sortMethod](row);
                }
            }
        }
    });
};

const CollapseListItem = (item) => {
    console.log(item);
}; //Todo: implement or remove?

const ExpandListItem = (item) => {
    console.log(item);
};//Todo: implement or remove?

const Update = (next?: CallbackInterface | undefined | null): void => { //Todo: implement or remove?
    setTimeout(() => {
        TileScrollMenu.Update();

        if (next) {
            next();
        }
    }, 100);
};

const resetSort = (btn) => {
    const l = btn.closest(".sortPanel").find(".sortBtn").not(btn);
    l.find("span").hide();
    l.find(".noToggle").show();
    btn.find(".noToggle").hide();
};

function toggleNameSort(e: JQueryEventObject): void {
    const tableRow = $(e.target).closest(".tableRow").first();

    const sortASC = !tableRow.data(sortProperty) || tableRow.data(sortProperty) === "false" ? false : true;
    sortByName(tableRow, sortASC);
    tableRow.data(sortProperty, !sortASC ? "true" : "false");
}

function toggleNumberSort(e: JQueryEventObject): void {
    const tableRow = $(e.target).closest(".tableRow").first();

    const sortASC = !tableRow.data("numberSort") || tableRow.data("numberSort") === "false" ? false : true;
    sortByNumber(tableRow, sortASC);
    tableRow.data("numberSort", !sortASC ? "true" : "false");
}

function toggleArrow(element, asc) {
    const jqElement = $(element);

    if (!asc) {
        jqElement.find(".downToggle").hide(0);
        jqElement.find(".upToggle").show(0);
    } else {
        jqElement.find(".upToggle").hide(0);
        jqElement.find(".downToggle").show(0);
    }
}

export function sortByName(tableRow, asc): void {
    if (!tableRow.hasClass("sortable")) {
        return;
    }

    asc = asc === false ? false : true;

    var lst = tableRow.find("ul");

    var sorted = lst.find("li").sort((a, b) => {
        var txtA = $(a).find(".textColumn").text().trim().toLowerCase();
        var txtB = $(b).find(".textColumn").text().trim().toLowerCase();

        if (asc) {
            if (txtA < txtB) {
                return -1;
            }
            if (txtA > txtB) {
                return 1;
            }
            return 0;
        }

        if (txtA > txtB) {
            return -1;
        }
        if (txtA < txtB) {
            return 1;
        }
        return 0;
    });

    lst.empty().append(sorted);
    var sortField = tableRow.find(".toggleNameSort");
    resetSort($(sortField));
    toggleArrow(sortField, !asc);
}

export function sortByNumber(tableRow, asc) {
    if (!tableRow.hasClass("sortable")) {
        return;
    }

    var sorted = tableRow.find("li").sort((a, b) => {
        var txtA: any = $(a).find(".numberColumn").text().trim().toLowerCase();
        var txtB: any = $(b).find(".numberColumn").text().trim().toLowerCase();

        if (asc) {
            return txtA - txtB;
        }

        return txtB - txtA;
    });

    tableRow.find("ul").empty().append(sorted);
    var sortField = tableRow.find(".toggleNumberSort");
    resetSort($(sortField));
    toggleArrow(sortField, !asc);
}

$(() => {
    setTimeout(() => {
        SelectListItem($(".tableRow-selected").last() || null);
        var nano = $(".nano");

        if (nano.nanoScroller) {
            nano.nanoScroller({ destroy: true }).nanoScroller({ alwaysVisible: false });
        }
    }, 100);

    $(".tableCellHeader a").on("click", function (event) {
        var tr = $(this).closest(".tableRow");
        var selected = tr.find("li.selected");

        if (selected.length) {
            $(this).attr("href", selected?.first()?.find("a")?.attr("href") as string);
            return true;
        }
    });

    $(".toggleNameSort").on("click", toggleNameSort);
    $(".toggleNumberSort").on("click", toggleNumberSort);

    $(".thIcon-toggleRow").on("click", function () {
        $(".tableRow .filterPanel").hide(0);
        $(".tableRow .sortPanel").hide(0);

        var tableRow = $(this).closest(".tableRow");
        SelectListItem(tableRow);
    });

    $(".thIcon-search").on("click", function () {
        var tableRow = $(this).closest(".tableRow");
        var filterPanel = tableRow.find(".filterPanel");
        var isFilterPanelVisible = filterPanel.is(":visible");

        filterPanel.toggle();
        var content = tableRow.find(".tableCellContent");
        var contentHeight = content.height();

        if (isFilterPanelVisible) {
            content.height((contentHeight as number) + (filterPanel.height() as number));
        } else {
            content.height((contentHeight as number) - (filterPanel.height() as number));
            filterPanel.find(".sideBarSearchInput").focus();
        }

        UpdateScrollBars();
    });

    $(".thIcon-sort").on("click", function () {
        var tableRow = $(this).closest(".tableRow");
        var sortPanel = tableRow.find(".sortPanel");
        var isFilterPanelVisible = sortPanel.is(":visible");

        sortPanel.toggle();
        var content = tableRow.find(".tableCellContent");
        var contentHeight = content.height();

        if (isFilterPanelVisible) {
            content.height((contentHeight as number) + (sortPanel.height() as number));
        } else {
            content.height((contentHeight as number) - (sortPanel.height() as number));
        }

        UpdateScrollBars();
    });
});

export const SideBar = {
    Update,
    UpdateScrollBars,
    SelectListItem,
    CollapseListItem,
    ExpandListItem,
    toggleNameSort,
    toggleNumberSort,
    sortByName
};