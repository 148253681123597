import { pageHelper } from "../pageHelper";

$(() => {
    var searchInput = $("#q");

    $("#MainPanelSearch .toggleMainSearchInfo").on("click", function (event) {
        $(this).closest(".searchControl").find(".searchInfo").toggle();
    });

    $("#MainPanelSearch .clearMainSearch").on("click", (event) => {
        event.stopPropagation();

        if (/\S/.test(searchInput.val() as string) === true) {
            $("#q").val("").prop("disabled", true);//.remove();
            $("#MainPanelSearch form").submit();
        }
    });

    if (searchInput.length) {
        if (/\S/.test(searchInput.val() as string) === true) {
            $("#MainPanelSearch").show();
            $("#MainPanelSearch .clearMainSearch").addClass("clearMainSearch-enabled");
        }
    }

    $(".mainPanelSearchBtn").on("click", () => {
        const searchPanel = $("#MainPanelSearch");

        $("#MainTitleBar").find(".btn").not(".mainPanelSearchBtn").not(".searchHelperButtons").toggle();

        if (!searchPanel.is(":visible")) {
            const q = pageHelper.GetQueryStringValue("q");

            if (q && q !== searchPanel.find("input").val()) {
                searchPanel.find("input").val(q);
            }
        }

        searchPanel.fadeToggle("fast", () => {
            if (searchPanel.is(":visible")) {
                searchPanel.find("input").focus();
            }
        });
    });

    $("#MainPanelSearch [data-clear-onchange]").on("change", function () {
        var onchangeClearData = $(this).data("clear-onchange");

        if (onchangeClearData) {
            var elements = onchangeClearData.split(",");

            for (var i = 0; i < elements.length; i++) {
                var element = $("#MainPanelSearch #" + elements[i]);
                element.val("");
                element.prop("disabled", true);
            }
        }
    });

    $("#MainPanelSearch select").on("change", function () {
        var select = $(this);
        if (!select.val()) {
            select.prop("disabled", true);
        }
        $("#MainPanelSearch form").submit();
    });

    $("#MainPanelSearch .enumValue").on("change", () => {
        $("#MainPanelSearch form").submit();
    });

    $(".input-group .clearFilter").on("click", function () {
        $(this).closest(".input-group").find("#q").val("").attr("disabled", "disabled");
        $("#MainPanelSearch form").submit();
    });
});