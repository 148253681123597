import { EasylonStorageManager } from "../EasylonStorageManager";
import { hideFloatingElements, ToggleSiteTop } from "../GlobalFunctions";

$(() => {
    $(".breadcrumbLink .rightPart").on("click", function () {
        var dropdown = $(this).parent().find(".dropdownMenu");

        var isVisible = dropdown.is(":visible");

        hideFloatingElements(dropdown, () => {
            if (!isVisible) {
                dropdown.fadeIn("fast");
            } else {
                dropdown.fadeOut("fast");
            }
        });
    });

    $(".breadcrumbSepparator").on("click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).find(".breadcrumbDropdown").toggle();
    });

    $(".breadcrumbBtn").on("click", function () {
        var bar = $(".breadCrumbContainer");
        var icon = $(this).find(".fa");

        bar.toggle();

        if (bar.is(":visible")) {
            icon.css("visibility", "visible");
            EasylonStorageManager.SetItem("ShowBreadCrumbs", "true");
        } else {
            icon.css("visibility", "hidden");
            EasylonStorageManager.SetItem("ShowBreadCrumbs", "false");
        }

        $(this).closest(".dropdownMenu").addClass("keepOpen");
        ToggleSiteTop();
    });

    $(".breadcrumbDropdownToggleBtn").on("click", function (event) {
        if ($(this).hasClass("noDropdownMenu")) {
            return;
        }

        const parent = $(this).closest(".breadcrumbMainNode");
        const dropdown = parent.find(".breadcrumbDropdown");
        const isVisible = dropdown.is(":visible");

        $(".breadcrumbDropdown").fadeOut(0);

        var maxHeight = 32 * 5;

        if (!isVisible) {
            dropdown.fadeIn(0, () => {
                const list = dropdown.find("ul");

                if (list) {
                    const height = list.height() as number;
                    if (height > maxHeight) {
                        dropdown.height(maxHeight);
                    } else {
                        dropdown.height(height);
                    }
                }


                dropdown.find(".nano").nanoScroller({ alwaysVisible: true });
            }).addClass("keepOpen");
        }
    });

    $(".topTileBarBtn").on("click", function () {
        var bar = $(".topTileBarContainer");
        var icon = $(this).find(".fa");

        bar.toggle();

        if (bar.is(":visible")) {
            icon.css("visibility", "visible");
            EasylonStorageManager.SetItem("ShowTileBar", "true");
        }
        else {
            icon.css("visibility", 'hidden');
            EasylonStorageManager.SetItem("ShowTileBar", "false");
        }

        $(this).closest(".dropdownMenu").addClass("keepOpen");
        ToggleSiteTop();
    });
});